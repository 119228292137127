import React, { useState } from 'react';
import useSanity from '../hooks/useSanity_pa';
import _ from 'lodash';

const SiteSettingsContext = React.createContext();

const SiteSettingsProvider = ({ children }) => {

    const query = `
        *[_type == "siteSettings" || _type == "companyInfo"] {
          _type == 'siteSettings' => {
            _type,
            description,
            "logo": logo.asset->url,
            slides[] {
              "imageUrl": asset->url
            },
            projects[]->{
              _id,
              title,
              "href": slug.current,
              "mainImage": mainImage.asset->url,
              accentColor,
              excerpt
            },
            "font": siteFont.asset->url
          },
          _type == 'companyInfo' => {
            _type,
            name,
            email,
            phone,
            address1,
            address2,
            city,
            zipCode
          }
        }
    `

  const {result, loading} = useSanity(query);
  
  return (
    <SiteSettingsContext.Provider value={{result, loading}}>
      {children}
    </SiteSettingsContext.Provider>
  )

}

export { SiteSettingsProvider, SiteSettingsContext };