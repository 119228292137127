import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import History from './icons/history'
import Dismiss from './icons/dismiss'
import BlockContent from '@sanity/block-content-to-react';

const Wrapper = styled.section`
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0rem 0rem 4rem;
`

const Action = styled.div`
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    & > svg {
        height: 1rem;
        width: 1rem;
        margin-right: 10px;
    }
`

const appear = (dismissedCSS) => keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: ${dismissedCSS ? 0 : 1 };
    }
`

function Icon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        x="0"
        y="0"
        viewBox="0 0 16 16"
      >
        <path
          fill="#444"
          d="M14.7 1.3c-.4-.4-1-.4-1.4 0L8 6.6 2.7 1.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L6.6 8l-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L8 9.4l5.3 5.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L9.4 8l5.3-5.3c.4-.4.4-1 0-1.4z"
        ></path>
      </svg>
    );
  }

const DismissableMessage = styled.div`
    
    /* animation: ${({dismissedCSS}) => appear(dismissedCSS)} 0.33s ease-out; */
    /* animation-fill-mode: forwards; */
    /* animation-iteration-count: 1; */
    background: #fff;
    /* box-shadow: 0px 3px 10px rgba(45, 45, 45, 0.05); */
    /* color: white; */
    transition: all 0.66s;
    /* border-radius: 6px; */
    border: 1px solid #dbdbdb;
    position: relative;
    overflow: hidden;
    margin: 0rem;
    opacity: ${({dismissedCSS}) => dismissedCSS ? 0 : 1 };
    transform: ${({dismissedCSS}) => dismissedCSS ? ' scale(0.66)' : 'scale(0.95)' };
    max-height: ${({dismissedCSS, height}) => dismissedCSS ? '0' : height === 0 ? 'auto' : `calc(${height}px)` };
    padding: ${({dismissedCSS}) => dismissedCSS ? '0rem 1rem 0rem 2rem' : '2.33rem 1rem 3rem 2rem' };
`

const MessageAction = styled.div`
    position: absolute;
    cursor: pointer;
    top: 1.33rem;
    right: 1.33rem;
    opacity: 1;
    width: 16px;
    height: 16px;
    padding: 6px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #EA5723; */
    & > svg {
        object-fit: contain;
        height: 80%;
        width: 80%;
        & path {
            stroke: white;
            fill: white;
        }
    }
    background: #EA5723;
`

const MessageTray = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: auto;
    grid-gap: 0rem;
`

const DismissableMessages = ({msgs}) => {

    const arrToObj = (arr) => {
        const temp = {};
        arr.forEach((v,i)=>{
            temp[v._id] = null;
        })
        return temp;
    }
    
    const [dismissed, dismiss] = useState(JSON.parse(localStorage.getItem('dismissed')) || []);
    const [dismissedCSS, dismissCSS] = useState(dismissed);
    const [maxHeights, setMaxHeights] = useState(JSON.parse(localStorage.getItem('heights')) || arrToObj(msgs))

    const dismissMessage = (msg) => {
        dismissCSS(oldArray => [...oldArray, msg]);
        // localStorage.setItem('dismissed', JSON.stringify([...dismissed, msg]));
        // localStorage.setItem('height', JSON.stringify(maxHeights));
    }


    const retrieveMessages = () => {
        dismiss([]);
        setTimeout(() => {
            dismissCSS([]);
        }, (1));
        // localStorage.setItem('dismissed', JSON.stringify([]));
    }

    return (
        <Wrapper>
            <MessageTray>
                {dismissedCSS.length ? <Action onClick={retrieveMessages}>
                    <History></History>Show Read Messages ({dismissedCSS.length})
                </Action>
                : <Action>&nbsp;</Action>}
                {msgs.map((msg, i)=> {
                    return !dismissed.includes(msg._id) ?
                    <DismissableMessage
                        onTransitionEnd={()=>{ if (dismissedCSS.includes(msg._id)) dismiss(oldArray => [...oldArray, msg._id]) }}
                        dismissedCSS={dismissedCSS.includes(msg._id)}
                        height={maxHeights[msg._id]}
                        ref={(el)=>{
                            if (!el) {
                                return;
                            }
                            if (maxHeights[msg._id] == null) {
                                setMaxHeights({...maxHeights,  [msg._id]: el.getBoundingClientRect().height})
                            }
                        }}
                    >
                        <MessageAction onClick={() => dismissMessage(msg._id) }>
                            <Icon color="#242424"></Icon>
                        </MessageAction>
                    <h2>{msg?.question}</h2>
                        <BlockContent blocks={msg?.answer}></BlockContent>
                    </DismissableMessage> : null
                })}
            </MessageTray>
        </Wrapper>
    )
}

export default DismissableMessages;