import React, { useState, useEffect, useContext, useRef } from 'react';
import {renderToString} from 'react-dom/server';
import {useSpring, a, config, useTransition, animated} from 'react-spring'

import { useDrag } from 'react-use-gesture'

import useMeasure from 'react-use-measure';

import styled, { keyframes } from 'styled-components';
import {BrowserRouter as Router, Route, Redirect, Switch, useParams, useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';

import Link from './Link'
import BlockContent from '@sanity/block-content-to-react';

import List from './List';
import Tray from './Tray';
import useSanity from './hooks/useSanity';
import Loader from './Loader';
import Nav from './Nav';
import Stories from './Stories'
import FourOhFour from './404'

import Farkle from './Farkle'
import Pointer from './icons/pointer';

import Sandbox from './Sandbox'

import HomepageTiles from './HomepageTiles'
import Client from './Client'
import Cursor from './Cursor'
import Trail from './Trail'

import ContentGroupWidget from './contentGroupWidget';
import ContentGroupAltWidget from './contentGroupAltWidget';
import DismissableMessages from './dismissableMessages';

import { SanityProvider } from './contexts/sanity';
import { ScrollDirectionContext } from './contexts/pa-scroll-dir';
import { LocaleContext } from './contexts/locale-context';


const appear = keyframes`
    0% {
        opacity: 0;
        /* line-height: 4; */
    }
    100% {
        opacity: 1;
        /* line-height: 1.75; */
    }
`

const App = () => {
    
    const [collapsed, collapse] = useState(true);


    const w = window.innerWidth/2;
    const h = window.innerHeight/2;
    const [cursorPosition, set] = useSpring(() => ({ xy: [w, h], config: { tension: 1000, friction: 100 } }));
    
    const scrollContext = useContext(ScrollDirectionContext);
    const [scrollDir, setScrollDir] = useState("scrolling up");
    const innerRef = useRef(null);

    const { locale, activeCursor } = useContext(LocaleContext);

    useEffect(() => {
       const threshold = 0;
       let lastScrollY = window.pageYOffset;
       let ticking = false;
   
       const div = innerRef.current;

       const updateScrollDir = () => {
         const scrollY = div.scrollTop;
           if (Math.abs(scrollY - lastScrollY) < threshold) {
             ticking = false;
             return;
           }
           if (scrollY > 0*window.innerHeight) {
             setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
           }
           lastScrollY = scrollY > 0 ? scrollY : 0;
           ticking = false;
       };
   
       const onScroll = () => {
         if (!ticking) {
           window.requestAnimationFrame(updateScrollDir);
           ticking = true;
         }
       };
       div.addEventListener("scroll", onScroll);
       return () => div.removeEventListener("scroll", onScroll);
     }, []);
   
     useEffect(() => {
       scrollContext.setScrollDirection(scrollDir);
     }, [scrollDir]);

     useEffect(()=>{
        setScrollDir('scrolling up')
     },[locale]);


    const [touch, setTouch] = useState(false);
    const enabler = () => {
        setTouch(true);
        console.log('confirmed touch')
    }
    const enabled = () => {
        console.log('working')
    }

    // const [ref, bounds] = useMeasure();

    return (
            <SanityProvider>
                <Router>
                    <Wrapper onTouchStart={!touch ? enabler : null} onMouseMove={e => set({ xy: [e.clientX, e.clientY] })} >
                        <Cursor activeCursor={activeCursor} cursorPosition={cursorPosition}></Cursor>
                        <Nav scrollDir={scrollDir} ></Nav>
                        <Main onTouchStart={touch ? enabled : null} ref={innerRef}>
                            <Switch>
                                {/* <Route path="/" exact component={DynamicPage} key={`route1`}></Route> */}
                                <Route path="/" exact>
                                    <Redirect to="/home" />
                                </Route>
                                <Route path="/sandbox" exact component={Sandbox} key={`routeS`}></Route>
                                <Route path="/stories" exact component={Stories} key={`route1`}></Route>
                                <Route path="/farkle" exact component={Farkle} key={`route1`}></Route>
                                <Route path="/:slug" component={DynamicPage} key={`route3`}></Route>
                                {/* <Route path="/:lang/:slug" component={DynamicPage} key={`route3`}></Route> */}
                                {/* <Route path="*" component={FourOhFour}/> */}
                            </Switch>
                        </Main>
                        {/* <Tray navHandler={collapse} navState={collapsed}/> */}
                    </Wrapper>
                </Router>
            </SanityProvider>
    )
}

const Answer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0rem 2rem;
    text-align: center;
    margin: 0 auto;

    & > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        & > li {
            display: inline-block;
            color: #0554A2;
            border-radius: 25px;
            padding: 10px 20px;
            margin: 1rem 1rem 0rem 0rem;
            /* cursor: pointer; */
        }
    }


    & > h1 {
    }
    & > p {
    }
    & > section {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 420px));
        grid-template-rows: repeat(auto);
        grid-gap: 8vw;
        padding: 8vh;
        text-align: left;
    }
    & > section > div {
        & > div {
            margin-right: 20px;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            overflow: hidden;
            & > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    & > select {
        display: inline-block;
        font-size: 16px;
        font-family: sans-serif;
        font-weight: 700;
        color: #444;
        line-height: 1.3;
        padding: .6em 2.4em .5em .8em;
        /* width: 100%; */
        /* max-width: 100%; */
        box-sizing: border-box;
        margin: 2rem 0 1rem;
        /* border: 1px solid #0f886f; */
        border: 1px solid white;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        border-radius: 5px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='16px' height='16px' viewBox='0 0 16 16'%3E%3Cg transform='translate(0, 0)'%3E%3Cpolygon fill='%23444444' points='8,12.6 0.3,4.9 1.7,3.4 8,9.7 14.3,3.4 15.7,4.9 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        background-size: 0.8rem auto, 100%;
    }

    & > select::-ms-expand {
        display: none;
    }
    & > select:hover {
        border-color: #888;
    }
    & > select:focus {
        border-color: #0f886f;
        box-shadow: 0 0 1px 3px #0f886f;
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
    }
    & > select > option {
        font-weight: normal;
    }

`



const flash = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`



const StaticPage = () => {
    const query = `
        *[_type == "faq"] {
            _id,
            "question": coalesce(question[$language], question.en),
            "answer": coalesce(answer[$language], answer.en),
            "author": {
                "name": author->name,
                "image": author->image.asset->url
            },
            publishedAt
        }
    `
    const [question, setQuestion] = useState('default');
    const [questions, pushQuestion] = useState([]);

    useEffect(()=>{
        if (question !== 'default') pushQuestion(oldArray => [...oldArray, _.find(data, {_id: question})]);
        // setQuestion('default')
    }, [question])

    const [data, loading] = useSanity(query);
    let qq = data[parseInt(question.slice(-1))];
    return (
        loading ? <Loader>Loading</Loader> :
        <section>
            <Answer>
                <h1>Frequently Asked Questions</h1>
                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <ul>
                    {_.difference(data, questions).map((q, i) => {
                        return <li onClick={() => { setQuestion(q._id)}} key={q._id}>{q.question}</li>
                    })}
                </ul>
                {/* <select name="questions" id="questions" value={question} onChange={(event) => { setQuestion(event.target.value)}}>
                    <option key={`faq-placeholder`} value="default" selected disabled>What would you like to know?</option>
                    {data.map((q, i) => {
                        return <option key={`question-${i}`} value={`question-${i}`}>{q.question}</option>
                    })}
                </select> */}
                {/* <section>
                    {questions.reduce((ary, ele) => {ary.unshift(ele); return ary}, []).map(ques => {
                        return (
                            <div>
                                {!Number.isNaN(parseInt(question.slice(-1))) && <BlockContent blocks={ques?.answer}></BlockContent> }
                                <p>Answered by {ques?.author?.name} on {ques?.publishedAt}</p>
                                <div>
                                    <img src={ques?.author?.image}></img>
                                </div>
                            </div>
                        )
                    })}
                </section> */}
            </Answer>
            <DismissableMessages msgs={questions.reduce((ary, ele) => {ary.unshift(ele); return ary}, [])}/>
        </section>
    )
}

const Button = styled(Link)`
        display: inline-block;
        outline: none;
        border-radius: 0px;
        padding: 1rem 1.66rem;
        font-size: 0.85rem;
        font-weight: 700;
        margin-top: 2rem;
        /* cursor: pointer; */
        background: rgba(255, 255, 255, 0.66);
        color: #242424;
        border: 2px solid white;
        
        & + a {
            margin-left: 1rem;
        }
    /* & > div > a:last-of-type {
        border: none;
        background: white;
        color: #0f886f;
        color: #242424;
        &:hover {
            background: black;
            color: white;
        }
    } */
`

const Hero = styled.section`
    width: 100%;
    height: 66vh;
    color: ${props => (props.s == 'Light') ? 'white' : 'inherit'};
    background: ${props => `url(${props.bg})`};
    background-size: cover;
    background-position: 50% 50%;
    box-shadow: 0px 5px 20px rgba(45, 45, 45, 0.2);
    display: flex;
    align-items: center;
    justify-content: ${props => (props.a == 'Left') ? 'flex-start' : (props.a == 'Right') ? 'flex-end' : 'center'};
    text-align: ${props => (props.a == 'Left') ? 'left' : (props.a == 'Right') ? 'right' : 'center'};
    /* padding: 0rem calc((100vw - 960px) / 2); */
    padding: 0rem 1rem;
    box-sizing: border-box;
    div {
        /* max-width: calc(100vw - 2rem); */
        max-width: 768px;
        margin: 0 auto;
    }
    @media only screen and (min-width: 768px) {
        div {
            /* max-width: 33vw; */
            /* margin: 0; */
        }
    }
`

const BlockTextWidget = styled.section`
    max-width: 960px;
    margin: 3rem auto;
    padding: 0rem 1.5rem 0rem 1rem;
`

const DefaultWrapper = styled.section`
    padding: 4rem 2rem;
    max-width: 50%;
`



const DynamicPage = () => {
    const query = `
        *[_type == "post" && slug.current == $slug][0] {
            "title": coalesce(title[$language], title.en),
            author->,
            widgets[] {
                _type == 'hero' => {
                    _key,
                    _type,
                    style,
                    alignment,
                    "background": background.asset->url,
                    "content": coalesce(content[$language], content.en),
                    actions[] {
                        "label": coalesce(text[$language], text.en),
                        destination
                    }
                },
                _type == 'textWidget' => {
                    _key,
                    _type,
                    "content": coalesce(content[$language], content.en),
                },
                _type == 'contentGroupAltWidget' => {
                    _key,
                    _type,
                    contentGroups[] {
                        "tag": coalesce(tag[$language], tag.en),
                        "mainContent": coalesce(mainContent[$language], mainContent.en),
                        "columnA": coalesce(columnA[$language], columnA.en),
                        "columnB": coalesce(columnB[$language], columnB.en),
                        "image": image.asset->url,
                    }
                },
                _type == 'contentGroupWidget' => {
                    _key,
                    _type,
                    orientation,
                    contentGroups[] {
                        "tag": coalesce(tag[$language], tag.en),
                        "content": coalesce(content[$language], content.en),
                        action {
                            "label": coalesce(text[$language], text.en),
                            destination
                        },
                        "image": image.asset->url,
                    }
                }
            },
            gallery->{
                "title": coalesce(title[$language], title.en),
                content[] {
                    "title": coalesce(title[$language], title.en),
                    "description": coalesce(description[$language], description.en),
                    "imageURL": image.asset->url,
                    "color1": image.asset->metadata.palette.darkVibrant.background,
                    "color2": image.asset->metadata.palette.lightVibrant.background,
                    "color3": image.asset->metadata.palette.vibrant.background
                }
            }
        }
    `
    let { slug } = useParams();
    let history = useHistory();
    let location = useLocation();

    const [params, setParams] = useState({slug: slug})
    const [data, loading] = useSanity(query, params);

    useEffect(() => {
        setParams({slug: slug})
    }, [slug]);

    const transition = useTransition(!loading, null, {
        from: { opacity: 0, transform: 'translateY(20px) skewY(1deg)' },
        enter: { opacity: 1, transform: 'translateY(0px) skewY(0deg)' },
        leave: { opacity: 0, transform: 'translateY(20px) skewY(1deg)' }
    });

    return (
        loading ? <Loader>Loading</Loader> :
        transition.map(
            ({ item, key, props }) =>
                item && (
                <animated.main key={key} style={props}>
                {/* <Client></Client> */}
                {/* <Client></Client> */}
                <DefaultWrapper>
                    <Trail>
                        <span>Amit Nehra</span>
                        <span>Digital Product Designer</span>
                        <span>Marketing Technologist</span>
                    </Trail>
                </DefaultWrapper>
                <DefaultWrapper>
                    <h2>Profile</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur explicabo nulla asperiores velit libero. Optio nam omnis error consequatur deleniti inventore, asperiores sint, facere maiores dolorem dolorum vel voluptate minus?</p>
                    <p>Placeat, reprehenderit nesciunt. Sapiente, blanditiis. Quam esse aspernatur unde maxime repudiandae quibusdam ducimus, quia hic, suscipit ullam blanditiis culpa vero quis possimus odio nihil. Laborum, quis enim! Vel, officiis nihil!</p>
                    <h2>Experience</h2>
                    <h3>Experience 1</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, quia eius nobis odit adipisci mollitia quaerat, necessitatibus aperiam accusantium explicabo nulla sapiente deleniti quidem in labore nesciunt veritatis veniam repellendus?</p>
                    <h3>Experience 2</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, quia eius nobis odit adipisci mollitia quaerat, necessitatibus aperiam accusantium explicabo nulla sapiente deleniti quidem in labore nesciunt veritatis veniam repellendus?</p>
                    <h3>Experience 3</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, quia eius nobis odit adipisci mollitia quaerat, necessitatibus aperiam accusantium explicabo nulla sapiente deleniti quidem in labore nesciunt veritatis veniam repellendus?</p>
                    <h2>Skills</h2>
                    <ul>
                        <li>Skill 1</li>
                        <li>Skill 2</li>
                        <li>Skill 3</li>
                        <li>Skill 4</li>
                    </ul>
                    <h2>Education</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur explicabo nulla asperiores velit libero. Optio nam omnis error consequatur deleniti inventore, asperiores sint, facere maiores dolorem dolorum vel voluptate minus?</p>
                    <p>Placeat, reprehenderit nesciunt. Sapiente, blanditiis. Quam esse aspernatur unde maxime repudiandae quibusdam ducimus, quia hic, suscipit ullam blanditiis culpa vero quis possimus odio nihil. Laborum, quis enim! Vel, officiis nihil!</p>
                </DefaultWrapper>
                <HomepageTiles></HomepageTiles>
                {data.widgets ? data.widgets.map((widget) => {
                    switch(widget._type) {
                        case 'hero':
                            return <Hero bg={widget.background} s={widget.style} a={widget.alignment} key={`wid-${widget._key}`}>
                                <div>
                                    <BlockContent blocks={widget.content}></BlockContent>
                                    {widget.actions && widget.actions.map((action) => {
                                        return <Button to={action.destination} key={action.label}>{action.label}</Button>
                                    })}
                                </div>
                            </Hero>;
                        case 'textWidget':
                            return <BlockTextWidget key={widget._key}>
                                <BlockContent blocks={widget.content}></BlockContent>
                            </BlockTextWidget>;
                        case 'contentGroupWidget':
                            return <ContentGroupWidget orientation={widget.orientation} groups={widget.contentGroups} key={widget._key}></ContentGroupWidget>
                        case 'contentGroupAltWidget':
                            return <ContentGroupAltWidget groups={widget.contentGroups} key={widget._key}></ContentGroupAltWidget>
                        default:
                            break;
                    }
                }) : <FourOhFour/>}
            </animated.main>
          )
        )
    )
}

const Photo = styled.div`
    position: relative;
    border-radius: 5%;
    width: 100%;
    padding-bottom: 100%;
    background: ${props => `url(${props.src})`};
    background-size: cover;
    background-position: 50% 50%;
    z-index: 1;
    perspective: 1000;
    transform-style: preserve-3d;
    &:after{
        content: '';
        position: absolute;
        background: ${props => `url(${props.src})`};
        background-size: cover;
        background-position: 50% 50%;
        bottom: 0px;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: translateZ(-100px) translateX(-50%) translateY(5.5%);
        filter: blur(6px);
        border-radius: 10px;
    }
    /* &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 50%;
        width: 100%;
        z-index: 2;
        background: linear-gradient(to top,rgba(69,69,69,0.9),rgba(69,69,69,0));
        background: ${props => `linear-gradient(to top, ${props.color1}, rgba(69,69,69,0))`};
    } */
    /* background: ${props => `linear-gradient(110deg, ${props.color1} 40%, rgba(0,0,0,0) 30%), radial-gradient(farthest-corner at 0% 0%, ${props.color2} 70%, ${props.color3} 70%)`}; */

`

const Gallery = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space-around;
    margin-bottom: 20vh;
    max-width: 768px;
    /* grid-template-rows: 1fr; */
    grid-gap: 3rem;
    padding: 3rem;
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
    align-items: center;
    & > div {
        /* border-radius: 0px; */
        /* overflow: hidden; */
        /* box-shadow: 0px 0px 8px rgba(69,69,69,0.20); */
        /* & > img {
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
        } */
    }
`

const Main = styled.div`
    height: 100%;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding-top: 103px;
    box-sizing: border-box;
    overflow: auto;
    ::-webkit-scrollbar {
    display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @media only screen and (min-width: 768px) {
        order: 2;
    }
`



const Wrapper = styled.main`
    height: 100%;
    box-sizing: border-box;
    color: #121212;
    background: #f6f6f6;
    font-family: 'IBM Plex Sans', 'Open Sans', sans-serif;
    cursor: none;
    /* width: 100%;     */
    /* max-width: 1920px; */
    margin: 0 auto;
    /* cursor: url(${"data:image/svg+xml,"+encodeURIComponent(renderToString(<Pointer color="black"/>))}), auto; */

    /* & > *::-webkit-scrollbar {
        display: none;
    } */
    
    /* color: #222c34; */
    /* display: grid; */
    /* grid-template-rows: 1fr; */
    /* padding-top: 65px; */
    @media only screen and (min-width: 768px) {
        /* grid-template-rows: 1fr; */
    }
    /* text-shadow: 1px 1px 1px rgba(0,0,0,0.1); */
    /* letter-spacing: 1px; */

    h1, h2, h3, p {
        /* animation: ${appear} 0.15s ease-out; */
        /* animation-fill-mode: forwards; */
        /* opacity: 0; */
    }

    h1,
    h2{
        font-family: 'IBM Plex Sans Condensed', serif;
        font-weight: 500;
    }
    h1 {
        font-size: 28px;
        margin: 0rem 0rem 1rem;
    }
    h2 {
        font-size: 18px;
        margin: 0rem 0rem 1rem;
    }
    p + h1,
    p + h2 {
        margin-top: 2rem;
    }
    p + p {
        margin-top: 1rem;
    }
    p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.75;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    img {
        display: block;
    }
    a {
        text-decoration: none;
        color: inherit;
        cursor: none;
    }

    @media only screen and (min-width: 768px) {
        h1 {
            font-size: 42px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
        }
    }

`


const Caption = styled.div`
    h3 {
        font-weight: 700;
        margin-top: 0rem;
        margin-bottom: 0.33rem;
    }
    p {
        margin: 0;
    }
    position: absolute;
    bottom: 0;
    z-index: 3;
    padding: 1rem 1rem 1.5rem;
    box-sizing: border-box;
    color: white;
    border-radius: 0px 0px 8px 8px;
    /* background: ${props => `linear-gradient(110deg, ${props.color1} 40%, rgba(0,0,0,0) 30%), radial-gradient(farthest-corner at 0% 0%, ${props.color2} 70%, ${props.color3} 70%)`}; */
    /* background-image: ${props => `radial-gradient( circle at 50%, ${props.color2} 10%, ${props.color3} 100%)`}; */
    background-color: ${props => props.color3};
`


export default App;