import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import LogoSVG from './logo-svg'


const StoriesWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: #121212;

    height: calc(var(--vh, 1vh) * 100);
        @media only screen and (min-width: 769px) {
        height: calc(var(--vh, 1vh) * 85);
    }

    & > div:nth-child(1) {
        display: flex;
        z-index: 1;
        position: absolute;
        width: 100%;
        top: 0px;
    }
    & > div:nth-child(2) {
        position: relative;
        height: 100vh;
        width: 100%;
        height: 100%;
    }
    & > div:nth-child(2) > div {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 65px);
        height: 100%;
        width: 100%;
        & > img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            object-position: 50% 50%;
            transition: all 1s;
        }
    }
    & > svg:nth-child(3) {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        height: 75px;
        fill: white;
    }
`

const shrink = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`

const StoryTimer = styled.div`
    opacity: 0;
    width: calc(100% - 10px);
    margin: 15px 5px 35px 5px;
    &:first-of-type {
        width: calc(100% - 15px);
        margin: 15px 5px 35px 10px;
    }
    &:last-of-type {
        width: calc(100% - 15px);
        margin: 15px 10px 35px 5px;
    }
    height: 3px;
    background: rgba(255, 255, 255, 0.33);
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    &::before {
        animation: ${({shown}) => {return shown ? shrink : 'none'}} 5s linear;
        animation-fill-mode: forwards;
        content: '';
        display: block;
        height: 100%;
        width: 0%;
        background: white;
    }
`

const Story = styled.div`
    opacity:  ${({shown}) => {return shown ? '1' : '0'}};
    transition: all 1s;
`

const Stories = ({backgroundImage}) => {

    const [story, setStory] = useState(0);

    return (
        <StoriesWrapper>
            <div>
                {backgroundImage?.map((s,i) => {
                    return (
                        <StoryTimer onAnimationEnd={()=>{setStory((oldStory) => {return (oldStory+1)%backgroundImage?.length })}} shown={story == i}/>
                    )
                })}
            </div>
            <div>
                {backgroundImage?.map((s,i) => {
                    return (
                        <Story shown={story == i}>
                            <img onClick={()=>{setStory((oldStory) => {return (oldStory+1)%backgroundImage?.length })}} src={s.imageUrl}/>
                        </Story>
                    )
                })}
            </div>
            {/* <LogoSVG/> */}
        </StoriesWrapper>
    )
}

export default Stories;