import React from 'react'
import styled, {keyframes} from 'styled-components'
import {Link} from 'react-router-dom';
import LogoSVG from './logo-svg';


const grow = keyframes`
    0% {
        width: 0px
    }
    100% {
        width: 20vw;
    }
`

const Wrapper = styled.div`
    display: block;
    position: sticky;
    top: calc(var(--vh, 1vh) * 10);
    width: 100%;
    /* width: 0%;
    animation: ${grow} 1s ease-in;
    animation-fill-mode: forwards;
    animation-delay: 4s; */

    height: calc(var(--vh, 1vh) * 15);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    & > a {
      display: block;
      width: 60%;
      width: 150px;
    }
    & img {
    }
`

const Logo = ({ logo }) => {
  return <Wrapper>
    <Link to="/projects">
      <LogoSVG/>
      {/* <img src={logo} /> */}
    </Link>
  </Wrapper>
}

export default Logo
