import React, { useState } from 'react';

const ScrollDirectionContext = React.createContext();

const ScrollDirectionProvider = ({ children }) => {

    const [scrollDirection, setScrollDirection] = useState(false);

    return (
        <ScrollDirectionContext.Provider value={{scrollDirection, setScrollDirection}}>
            {children}
        </ScrollDirectionContext.Provider>
    )
}

export { ScrollDirectionProvider, ScrollDirectionContext };