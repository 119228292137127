import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocaleContext } from './contexts/locale-context';

const Wrapper = styled.ul`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
`

const Locale = styled.li`
    flex-basis: 1fr;
    font-weight: ${props => props.active ? '700' : '400'};
    padding: 0px 8px;
    & + li {
        /* border-left: 1px solid #dbdbdb; */
        /* margin-left: 10px; */
    }
`

const LocalizationToggle = ({ clickHandler=(()=>{}) }) => {
    const { locale, locales, changeLocale, activateCursor } = useContext(LocaleContext);
    return (
        <Wrapper>
            {locales.map((l) => {
                return <Locale onMouseOver={()=>activateCursor(true)} onMouseOut={()=>activateCursor(false)} active={l.id===locale} onClick={()=>{ changeLocale(l.id); clickHandler(); }}>{l.title}</Locale> 
            })}
        </Wrapper>
    )
}

export default LocalizationToggle;