// 2-two roll again
// delayed farkle

import React, { useState, useEffect, useLayoutEffect, useReducer, useMemo } from 'react';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import _ from 'lodash';

import Modal from './Modal';
import Scoreboard from './Scoreboard';
import Button from './Button';

const Main = styled.main`
    filter: ${props => props.defocus ? 'blur(5px)' : 'none'};
`

const Wrapper = styled.div`
    letter-spacing: 1px;
    min-height: 100vh;
    color: #f5f5f5;
    background: #242424;
    background: #2B5B3F;
    box-sizing: border-box;
    height: 100vh;
    color: #f5f5f5;
    overflow: scroll;
    /* display: grid; */
    /* grid-template-columns: 80vw 20vw; */
    /* grid-template-columns: 100vw; */
    & > main {
        /* background: #114727; */
        background: #2B5B3F;

        /* display: grid; */
        /* grid-template-rows: 80vh 20vh; */
        /* grid-template-rows: 100vh; */
        & > div {
            /* min-height: 100vh; */
            padding: 1rem;
            box-sizing: border-box;
            /* overflow: hidden; */
            & > div {
              min-height: 100px;
            }
        }
        & > footer {
            display: none;
            background: red;
            margin: 0;
            padding: 1rem 3rem;
            box-sizing: border-box;
        }
    }
    & > aside {
        background: #0A140E;
        background: #0B2F1A;
        background: #183e28;
        background: #0b2717;

        padding: 3rem 1rem;
        box-sizing: border-box;
    }
`

const Tray = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 20px 10px;
    position: relative;
    z-index: 0;
    margin-top: 1rem;
    color: #242424;
    /* padding: 0rem 0rem 4rem; */
`




const Die = styled.div`
    position: absolute;
    top: 0;

    /* background: linear-gradient(135deg, #d5d5d5 0%, #959595 84%); */
    & > div {
        display: grid;
        grid-template-columns: repeat(3, calc(100%/3));
        grid-template-rows: repeat(3, calc(100%/3));
        /* background: linear-gradient(135deg, #f5f5f5 0%, #d5d5d5 84%); */
        height: 100%;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 25px;
    }
    /* border-radius: 10px; */
    height: 100%;
    width: 100%;
    color: inherit;
    background: #f5f5f5;
    
    box-shadow: ${props => props.active ? 'gold' : 'none'};

    & > div > div {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        transform: scale(0.75);
        background: #242424;
    }
    &::after {
        /* content: ''; */
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #b5b5b5;
        /* border-radius: 10px; */
    }
    &::before {
        content: '';
        position: absolute;
        /* display: block; */
        width: 130%;
        height: 130%;
        background: linear-gradient(to right, #050A07 0%,#2B5B3F 84%);
        top: 50%;
        left: 50%;
        transform: rotate(45deg) translate(-0%, -50%);
        transform-origin: 0% 0%;
        opacity: 0.33;
        filter: blur(5px);
        clip-path: circle(85% at 0% 50%);
        z-index: -1;
        touch-action: none;
    }
`

const Scene = styled.div`
    display: inline-block;
    /* padding-bottom: 100%; */
    /* height: 100%; */
    /* width: 100%; */
    perspective: 600px;
    
  &::before {
    content: "";
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }

    ${Die} {
      position: absolute;
    }

    /* &::before { */
        /* content: ''; */
        /* position: absolute;
        display: block;
        width: 130%;
        height: 130%;
        background: linear-gradient(to right, #050A07 0%,#2B5B3F 84%);
        top: 50%;
        left: 50%;
        transform: rotate(45deg) translate(-0%, -50%);
        transform-origin: 0% 0%;
        opacity: 0.33;
        filter: blur(5px);
        clip-path: circle(85% at 0% 50%);
        z-index: -1; */
    /* } */
`

const sideTransformer = (side) => {
    switch(side) {
        /* front (5) */
        case 5:
            return 'rotateY(0deg)';

        /* back (2) */
        case 2:
            return 'rotateY(180deg)';

        /* left (3) */
        case 3:
            return 'rotateY(90deg)';

        /* right (4) */
        case 4:
            return 'rotateY(-90deg)';
        
        /* top (1) */
        case 1:
            return 'rotateX(-90deg)';

        /* bottom (6) */
        case 6:
            return 'rotateX(90deg)';
    }
}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
  }

const shuffle = (props) => {
    return keyframes`
        0% {
            /* transform: ${`rotateX(${getRandomIntInclusive(-90,90)}deg) rotateY(${getRandomIntInclusive(-90,90)}deg)`} */
            transform: rotateY(45deg) rotateY(90deg);
        }
        100% {
            /* transform: ${sideTransformer(props.position)} */
            transform: rotateY(-90deg) rotateY(10deg);
        }
    `
}


const shuffle2 = keyframes`
    0% {
        transform: initial;
    }
    25% {
        transform: rotateX(-23deg) rotateY(85deg) rotateZ(-23deg);
    }
    50% {
        transform: rotateX(45deg) rotateY(33deg) rotateZ(90deg);
    }
`

const Cube = styled.div`
    width: inherit;
    height: inherit;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: ${props => sideTransformer(props.face)};
    will-change: transform;
    animation: ${props => props.shouldAnimateDice ? css`${shuffle2} 1s linear;` : 'none'};
    transition: ${props => props.shouldAnimateDice ? `transform 1s` : 'none'};

    ${Die} {
        position: absolute;
        &::before {
            display: none;
        }
        /* front (5) */
        &:nth-child(1) {
            transform: translate3d(0, 0, calc(50px/2));
        }  
        /* back (2) */
        &:nth-child(2) {
            transform: rotateY(180deg) translate3d(0, 0, calc(50px/2));
        } 
        /* left (3) */
        &:nth-child(3) {
            transform: rotateY(-90deg) translate3d(0, 0, calc(50px/2));
        } 
        /* right (4) */
        &:nth-child(4) {
            transform: rotateY(90deg) translate3d(0, 0, calc(50px/2));
        } 
        /* top (1) */
        &:nth-child(5) {
            transform: rotateX(90deg) translate3d(0, 0, calc(50px/2));
        } 
        /* bottom (6) */
        &:nth-child(6) {
            transform: rotateX(-90deg) translate3d(0, 0, calc(50px/2));
        }
    }
`

const Set = styled.div`
    display: grid;
    grid-template-columns: ${props=> `repeat(${props.span}, 1fr)`};
    grid-column: ${props=> `span ${props.span}`};
    grid-gap: .66rem;
    justify-content: center;
    & > span {
        font-family: monospace;
        font-style: italic;
        font-size: 0.8rem;
        color: white;
        opacity: 0.66;
        /* width: 100%; */
        /* text-align: center; */
    }
`

function straight(toss) {
    return _.keys(_.groupBy(toss)).length === 6
}

function triplets(toss) {
    return toss.length == 6
    && _.chain(toss)
    .groupBy()
    .values()
    .map((v)=>v.length==2)
    .every()
    .value();
}

function score(toss) {
    let score = 0;
    let sets = {
        scoring: [],
        dead: []
    };
    if (straight(toss)) {
        score = score + 1500;
        sets.scoring.push(toss);
    } else if (triplets(toss)) {
        score = score + 750;
        sets.scoring.push(toss);
    } else {
        _.chain(toss)
        .groupBy()
        .each((set, die) => {
            if (set.length < 3) {
                switch(die) {
                    case '1':
                        score = score + set.length*100;
                        sets.scoring.push(..._.chunk(set, 1));
                        break;
                    case '5':
                        score = score + set.length*50;
                        sets.scoring.push(..._.chunk(set, 1));
                        break;
                    default:
                        sets.dead.push(..._.chunk(set, 1));
                        break;
                }
            } else {
                switch(die) {
                    case '1':
                        score = score + 1000 + (set.length-3)*1000;
                        sets.scoring.push(set);
                        break;
                    default:
                        score = score + die*100 + (set.length-3)*die*100;
                        sets.scoring.push(set);
                        break;
                }
            }
        })
        .value()
    }
    return {score, sets};
}


const rollDice = (numDice) => {
    return Array.from(Array(numDice), () => _.random(1,6));
}

const defaultGameState = {
    dice: {
        table: [],
        hold: [],
        temp: []
    },
    points: {
        table: 0,
        hold: 0,
        temp: 0
    },
    sets: {
        table: {
            scoring: [],
            dead: []
        },
        hold: {
            scoring: [],
            dead: []
        },
        temp: {
            scoring: [],
            dead: []
        }
    },
    rounds: [],
    broadcast: false,
    canBank: false,
    preventRoll: false,
    achievedMinimum: false,
    farkled: false,
    shouldAnimateDice: false
}

const defaultRoundState = {
    dice: {
        ...defaultGameState.dice
    },
    points: {
        ...defaultGameState.points
    },
    sets: {
        ...defaultGameState.sets
    }
}

function rollToTable(payload) {
    return {
        action: 'rollToTable',
        payload,
    };
}

const DIE = [
    [0,0,0,0,1,0,0,0,0],
    [1,0,0,0,0,0,0,0,1],
    [1,0,0,0,1,0,0,0,1],
    [1,0,1,0,0,0,1,0,1],
    [1,0,1,0,1,0,1,0,1],
    [1,0,1,1,0,1,1,0,1],
];

const dieFace = (face) => {
    return (
        <div>
            {DIE[face-1].map((f)=>{
                return f ? <div></div> : <span></span>;
            })}
        </div>
    )
}

const createPlayers = (players) => {
    return Array.from(Array(players), () => useReducer(reducer, defaultGameState));
}

const reducer = (gameState, dispatch) => {
    switch(dispatch.action) {
        case 'receiveState':
            return {
                ...dispatch.payload,
                broadcast: false
            }
        case 'rollToTable':
            return {
                ...gameState,
                dice: {
                    table: dispatch.payload,
                    hold: [],
                    temp: [...gameState.dice.temp, ...gameState.dice.hold]
                },
                points: {
                    ...gameState.points,
                    hold: 0,
                    temp: gameState.points.temp+gameState.points.hold
                },
                sets: {
                    ...gameState.sets,
                    temp: {
                        scoring: [...gameState.sets.temp.scoring, ...gameState.sets.hold.scoring],
                        dead: [...gameState.sets.temp.dead, ...gameState.sets.hold.dead]
                    }
                },
                shouldAnimateDice: true,
                broadcast: true
            }
        case 'xferToHold':
            const table = gameState.dice.table;
            const hold = gameState.dice.hold;
            return {
                ...gameState,
                dice: {
                    ...gameState.dice,
                    table: [...table.slice(0,dispatch.payload.position), ...table.slice(dispatch.payload.position+1)],
                    hold: [...hold, dispatch.payload.die]
                },
                shouldAnimateDice: false,
                broadcast: true
            }
        case 'xferToTable':
            return {
                ...gameState,
                dice: {
                    ...gameState.dice,
                    table: [...gameState.dice.table, dispatch.payload.die],
                    hold: [...gameState.dice.hold.slice(0,dispatch.payload.position), ...gameState.dice.hold.slice(dispatch.payload.position+1)]
                },
                broadcast: true
            }
        case 'rescore':
            const t = score(gameState.dice.table);
            const h = score(gameState.dice.hold);
            return {
                ...gameState,
                points: {
                    ...gameState.points,
                    table: t.score,
                    hold: h.score
                },
                sets: {
                    ...gameState.sets,
                    table: t.sets,
                    hold: h.sets
                },
                farkled: _.flatten(score([...gameState.dice.table, ...gameState.dice.hold]).sets.scoring).length === 0 && [...gameState.dice.table, ...gameState.dice.hold].length > 0,
                freeRoll: false
            }
        case 'endTurn':
            return {
                ...gameState,
                ...defaultRoundState,
                rounds: [...gameState.rounds, gameState.points.temp + gameState.points.hold]
            }
        case 'farkle':
            return {
                ...gameState,
                farkled: true,
                // rounds: [...gameState.rounds, gameState.rounds.length >= 2 && !gameState.rounds[gameState.rounds.length-1] && !gameState.rounds[gameState.rounds.length-2] ? -500 : 0]
            }
        case 'completeFarkle':
            return {
                ...gameState,
                ...defaultRoundState,
                farkled: false,
                rounds: [...gameState.rounds, ((gameState.rounds.length >= 2) && (
                    (gameState.rounds[gameState.rounds.length-1] == 0 && gameState.rounds[gameState.rounds.length-2] == 0) ||
                    (gameState.rounds[gameState.rounds.length-1] == 0 && gameState.rounds[gameState.rounds.length-2] == -500)
                )) ? -500 : 0]
            }
        case 'achievedMinimum':
            return {
                ...gameState,
                achievedMinimum: dispatch.payload
            }
        case 'preventRoll':
            return {
                ...gameState,
                preventRoll: dispatch.payload
            }
        case 'canBank':
            return {
                ...gameState,
                canBank: dispatch.payload
            }
        case 'restart':
            return defaultGameState;
        default:
            return gameState;
    }
}

const availableDice = (dice) => {
    const heldDice = dice.hold.length + dice.temp.length;
    return 6-(heldDice%6)||6;
}

const Gameplay = ({names, sendHandler, rx}) => {

    const [player, setPlayer] = useState(0);
    let players = createPlayers(names.length);
    const [state, dispatch] = players[player];

    // Scoring
    useLayoutEffect(()=>{
        dispatch({action: 'rescore'});
    }, [state.dice])

    useLayoutEffect(()=>{
        // Prevent Roll Detection
        if ((state.sets.hold.dead.length > 0) || ((state.sets.hold.scoring.length == 0) && !(state.dice.table.length == 0))) {
            dispatch({action: 'preventRoll', payload: true});
        } else {
            dispatch({action: 'preventRoll', payload: false});
        }
        // Minimum Score Detection
        if (_.sum(state.rounds) <= 0) {
            if (state.points.hold + state.points.temp >= 500) {
                dispatch({action: 'achievedMinimum', payload: true});
            } else {
                dispatch({action: 'achievedMinimum', payload: false});
            }
        }

    }, [state.points]);

    // useEffect(()=>{
    //   if (state.broadcast) sendHandler({ type: "STATE_CHANGE", content: state})
    // }, [state]);

    // useEffect(()=>{
    //     if (rx.broadcast) {
    //         dispatch({action: 'receiveState', payload: rx});
    //     }
    // }, [rx]);

    const bank = () => {
        setPlayer((player+1)%names.length);
        dispatch({action: 'endTurn'});
    }

    const roll = () => {
        dispatch(rollToTable(rollDice(availableDice(state.dice))));
    }

    const completeFarkle = () => {
        setPlayer((player+1)%names.length);
        dispatch({action: 'completeFarkle'});
    }
    const canBank = (!(state.achievedMinimum && state.sets.hold.scoring.length > 0 && state.sets.hold.dead == 0));
    // if (!(state.achievedMinimum && state.sets.hold.scoring.length > 0 && state.sets.hold.dead == 0)) {
        // dispatch({action: 'canBank', payload: true});
    // } else {
        // dispatch({action: 'canBank', payload: false});
    // }

    return (
        <>
            {state.farkled && <DialogBox message="You Farkled!" action={completeFarkle} actionLabel="End Turn"/>}
            <Main defocus={state.farkled}>
                <div>
                    <div>
                        <Tray>
                            {state.sets.temp.scoring.map((set) => {
                                return <Set span={set.length}>
                                    {set.map((v, i) => {
                                        return <Scene><Die key={i}>{dieFace(v)}</Die></Scene>
                                    })}
                                    <span>{score(set).score}</span>
                                </Set>
                            })}
                        </Tray>
                    </div>
                    <div>
                        <Tray>
                            {state.dice.hold.map((v, i) => {
                                return <Scene><Die key={i} onTouchEnd={
                                  (e) => {
                                    if (confirmTouch(e))  dispatch({action: 'xferToTable', payload: {die: v, position: i}});
                                }}>{dieFace(v)}</Die></Scene>
                            })}
                        </Tray>
                    </div>
                    <div>
                        <Tray>
                            {state.dice.table.map((v, i) => {
                                return (
                                    <AnimatedDice handler={() => {dispatch({action: 'xferToHold', payload: {die: v, position: i}});}} face={v} shouldAnimateDice={state.shouldAnimateDice}></AnimatedDice>
                                )
                            })}
                        </Tray>
                    </div>
                    <div>
                        <Button disabled={canBank} action={bank}>Bank {state.points.hold + state.points.temp} points</Button>
                        <Button disabled={state.preventRoll} action={roll}>Roll!</Button>
                    </div>
                </div>
            </Main>
            <Scoreboard defocus={state.farkled} players={players.map((p)=>{return p[0].rounds})} player={player} setPlayer={setPlayer} names={names}></Scoreboard>
        </>
    )
}

const Shade = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
`

const DialogBoxComponent = styled.div`
    ${Button} {
        margin: 2rem auto 0rem;
    }
    & > h1 {
        font-weight: 300;
        font-style: italic;
    }
    & > p {
        max-width: 66%;
        margin: 0 auto;
    }
`
const DialogBox = ({message, action, actionLabel}) => {
    return (
        <Shade>
            <Modal>
                <DialogBoxComponent>
                    <h1>{message}</h1>
                    <p>You lost all points in your hold and will bank 0 points.</p>
                    <Button action={action}>{actionLabel}</Button>
                </DialogBoxComponent>
            </Modal>
        </Shade>
    )
}

const confirmTouch = (event) => {
  var changedTouch = event.changedTouches[0];
  return document.elementFromPoint(changedTouch.clientX, changedTouch.clientY) === event.target
}

const AnimatedDice = ({face, handler, shouldAnimateDice}) => {
    const orientation = [5,2,3,4,1,6];
    return (
      
        <Scene onTouchEnd={(e) => { if (confirmTouch(e)) {handler()} }}>
            <Cube face={face} shouldAnimateDice={shouldAnimateDice}>
                {orientation.map((v,i) => {
                    return <Die>{dieFace(v)}</Die>
                })}
            </Cube>
        </Scene>
    )
}


// Number of Players Selector

const StepSelector = styled.div`
    height: 56px;
    width: 100%;
    display: flex;
    border-radius: 6px;
    margin: 2rem 0rem;
`
const Step = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1fr;
    line-height: 56px;
    border-radius: 6px;
    background: ${props => props.active ? 'rgba(0,0,0,0.66)': 'transparent'};
    font-weight: ${props => props.active ? '700': '300'};
    cursor: pointer;
    &:hover {
        background: rgba(0,0,0,0.33);
        /* border: 2px solid white; */
    }
`


// Name Input fields

const Input = styled.input`
    border: none;
    outline: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin-top: 1rem;
    background: rgba(255,255,255,0.33);
    color: white;
    border: 1px solid #050A07;
    /* box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.33); */
`

const handleFocus = (event) => event.target.select();

const NameInputs = ({names, handler}) => {
    return names.map((v, i) => {
        return <Input key={`ni-${i}`} onFocus={handleFocus} onChange={(e) => {handler(i, e)}} type="text" value={v} />
    })
}


// How to Play Modal



const HowToPlay = () =>{
    return (
        <div>
            <Tray>
            </Tray>
        </div>
    )
}



// Main Component

const Farkle = () => {

    const [numPlayers, setNumPlayers] = useState(1);
    const [gamePlay, startGamePlay] = useState(false);
    const [names, setNames] = useState(["Player 1"]);

    const [server, setServer] = useState();
    const [clients, setClients] = useState([]);
    const [userID, setUserID] = useState('');

    const [data, setData] = useState({});

    const start = () => {
        console.log('sdfsd')
        startGamePlay(true);
        // send2server({
        //     type: "START_GAMEPLAY",
        //     content: true
        // })
    }

    const updatePlayers = (n) => {
        setNumPlayers(n);
        const difference = n-names.length;
        if (difference < 0) {
            setNames(names.slice(0,n))
        } else {
            setNames([...names, ...Array.from(Array(difference), (v,i) => `Player ${i+names.length + 1}`)])
        }
    }

    useEffect(()=>{
        updatePlayers(clients.length);
        setNames(clients);
    },[clients])

    const updatePlayerName = (player, event) => {
        const newNames = [
            ...names.slice(0, player),
            event.target.value,
            ...names.slice(player+1)
        ];
        setNames(newNames);
    }

    // Send data
    const send2server = (payload) => {
        server.send(JSON.stringify(payload));
    }

    // // Set up WebSocket connection
    // useEffect(()=>{
    //     const ws = new WebSocket('ws://192.168.1.12:9898');
    //     ws.onopen = () => {
    //         console.log('WebSocket client has connected.');
    //         setServer(ws);
    //     };
    //     ws.onmessage = (e) => {
    //         const data = JSON.parse(e.data);
    //         switch (data.type) {
    //             case 'PLAYER_UPDATE':
    //                 setClients(data.content);
    //                 setUserID(data.userID);
    //                 break;
    //             case 'STATE_CHANGE':
    //                 setData(data.content);
    //                 break;
    //             case 'START_GAMEPLAY':
    //                 startGamePlay(data.content);
    //         }
    //     }
    // }, [])
   
    return (
        <Wrapper>
            <>
            { !gamePlay && <Modal>
                <h1>Farkle</h1>
                <h2>{userID}</h2>
                {/* <ul>
                    {clients.map((c) => {
                        return <li>{c}</li>
                    })}
                </ul> */}
                {/* <p>How many players?</p> */}
                {/* <Dropdown values={playerOptions} selected={numPlayers} handler={updatePlayers}/> */}
                <StepSelector>
                    {Array.from(Array(6), (v,i) => i).map((v, i) => {
                        return <Step key={`step-${i}`} active={numPlayers-1 == v} onMouseDown={()=> updatePlayers(v+1) }>{v+1}</Step>;
                    })}
                </StepSelector>
                {/* <p>What are their names?</p> */}
                <NameInputs names={names} handler={updatePlayerName}></NameInputs>
                <Button action={start}>Start Game</Button>
            </Modal>}
            {gamePlay && <Gameplay rx={data} sendHandler={send2server} numPlayers={numPlayers} names={names}></Gameplay>}
            </>
        </Wrapper>
    )
}

export default Farkle;