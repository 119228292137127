import React from "react";

function Icon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      x="0"
      y="0"
      viewBox="0 0 24 24"
    >
      <g fill={color}>
        <path d="M11 5v7a1 1 0 001 1h7v-2h-6V5z"></path>
        <path d="M12 0A12.013 12.013 0 000 12h2a10.016 10.016 0 114 8l1.8-2.4-7.2-.4L3 24l1.8-2.406A12 12 0 1012 0z"></path>
      </g>
    </svg>
  );
}

Icon.defaultProps = {
  color: '#242424'
}

export default Icon;