import React from "react";

function Icon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 16 16"
    >
     <path
        d="M.5.5l15 4-6 3 5 5a1.414 1.414 0 010 2h0a1.414 1.414 0 01-2 0l-5-5-3 6z"
        fill="#FFFFFF"
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-cap="butt"
      />
    </svg>
  );
}

Icon.defaultProps = {
    color: '#242424'
}

export default Icon;
