// Libraries
import React, { useState, useRef } from 'react'
import styled from 'styled-components'
// import { Link } from 'gatsby'
import Icon from '../icons/view-project_right-arrow'
import {BrowserRouter as Router, Route, Link, Switch, useParams, useHistory, useLocation} from 'react-router-dom';
import Observer from '@researchgate/react-intersection-observer';


import { useSpring, animated as a } from 'react-spring'
import { useEffect } from 'react';





const Card = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;

    /* a {
      perspective: 600px;
    } */

    & > a:hover + div a > svg {
      transform: translateX(5px);
    }

    & > *:first-child {
      width: 100%;
      padding-bottom: 90%;
      background-image: url("${props => props.backgroundImage}?h=1024&fit=max");
      background-size: cover;
      /* & > div {
        border: 2px solid red;
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 50%;
        box-sizing: border-box;
      } */
    }

    @media only screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-auto-flow: dense;
        /* & + & {
          margin-top: 10rem;
        } */
        & > *:first-child {
            grid-column: 2;
            padding-bottom: 180%;
        }
        & > *:last-child {
            grid-column: 1;
        }
    }
`
const CardContent = styled.div`
  background-color: ${props => props.backgroundColor};
  color: white;
  padding: 30px 5% 60px;
  h3 {
    margin: 0;
    font-size: 20px;
  }
  p {
    margin: 10px 0px 20px;
    font-size: 0.95rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.8rem;
  }
  a:hover > svg {
    transform: translateX(5px);
  }
  a svg {
    display: inline-block;
    height: 0.8rem;
    width: 0.8rem;
    vertical-align: bottom;
    margin-bottom: 3px;
    transition: transform 0.15s;
    transform: translateX(0px);
  }

  @media only screen and (min-width: 768px) {
    padding: 40px 40px 60px;
    display: grid;
    align-content: end;
  }
`

const getPrintableThreshold = (ratio, range) =>
  range.reduce((prev, curr) =>
    Math.abs(curr - ratio) < Math.abs(prev - ratio) ? curr : prev);

const ProjectCard = ({ card }) => {

  const [visible, setVisible] = useState(false)

  const { transform, opacity } = useSpring({
    opacity: visible ? 1 : 0,
    // transform: `perspective(600px) rotateX(${visible ? 0 : 4}deg) translateY(${visible ? 0 : 15}%)`,
    // transform: `perspective(600px) rotateX(${visible ? 0 : 4}deg) translate3d(0, ${visible ? 0 : 15}%, 0)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })


  const handleIntersection = ({ isIntersecting, intersectionRatio }) => {
    // console.log(getPrintableThreshold(
    //   intersectionRatio.toFixed(2),
    //   [0, 0.25, 0.5, 0.75, 1]
    // ))
    setVisible(isIntersecting)
    // console.log(event.isIntersecting);
  }

  // const CardRef = useRef();

  // useEffect(()=>{
  //   const pos = CardRef.current.getBoundingClientRect().top;
  //   console.log(pos/window.innerHeight);
  // })

  const options = {
    onChange: handleIntersection,
    rootMargin: '0% 0% 5%',
    threshold: [0, 0.25, 0.5, 0.75, 1]
  };

  return (
    <Observer {...options}>
      <a.div style={{opacity, transform}}>
      <Card backgroundImage={card.mainImage}>
        {card.mainImage && <Link to={`/projects/${card.href}`}>
            <div></div>
          </Link>}
        <CardContent backgroundColor={card.accentColor}>
          <h3>{card.title}</h3>
          <p>{card.excerpt}</p>
          <Link to={`/projects/${card.href}`}>
              View Project <Icon/>
          </Link>
        </CardContent>
      </Card>
      </a.div>
    </Observer>
  )
}

export default ProjectCard
