import React from 'react';
import sanityClient from '@sanity/client';

const SanityContext = React.createContext();

const SanityProvider = ({ children }) => {
    
  const client = sanityClient({
    projectId: '403afmyn',
    dataset: 'production',
    token: 'skiXB59yxfMRvI8biHRNaYjUPIY3dcuvTGfqZAcufI57mkyPhXmlDVY3zWYvnZ3aiStqOKyEcfoC1ONbWLbtDeAEQvUfoAUFziX0uAreFVGkOftqXOf4CTUPvR9xF1N3uLwRUK2jWwzLhWECb2rqijJugjpgmYpVBxOItd20LsXs3adAtqIK', // or leave blank to be anonymous user
    useCdn: false,
    ignoreBrowserTokenWarning: true
  })

  async function getData(query, params) {
    return await client.fetch(query, params);
  }

  return (
    <SanityContext.Provider value={getData}>
      {children}
    </SanityContext.Provider>
  )

}

export { SanityProvider, SanityContext };