import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
    border: none;
    outline: none;
    padding: 1.33rem 1.6rem;
    width: 100%;
    opacity: ${props => props.pressed && !props.disabled ? '.5' : '1'};
    transform: ${props => props.pressed || props.disabled ? 'scale(.98)' : 'scale(1)'};
    box-shadow: ${props => props.pressed || props.disabled ? 'none' : '0px 0px 14px -3px rgba(0,0,0,0.33)'};
    transition: all .33s;
    display: inline-block;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight: bold;
    /* letter-spacing: 1px; */
    background: #f5f5f5;
    /* background: #C8102E; */
    /* color: white; */
    /* background: #092414; */
    /* border-top: 1px solid #2B5B3F; */
    /* border-bottom: 2px solid #06180D; */
    /* color: white; */
    /* text-transform: uppercase; */
    /* margin: 2rem 1rem 0rem; */
    &:disabled {
        opacity: 0.5;
    }
    margin-top: 1rem;
`

const confirmTouch = (event) => {
    var changedTouch = event.changedTouches[0];
    return document.elementFromPoint(changedTouch.clientX, changedTouch.clientY) === event.target
}



const Button = ({disabled, action, children}) => {
    const [pressed, press] = useState(false);
    
    const completeAction = (e) => {
        if (confirmTouch(e) && !disabled) {
            action();
        }
        press(false);
    }

    const detectLossOfTarget = (e) => {
        if (confirmTouch(e)) {
            press(true);
            console.log('pressed')
        } else {
            press(false);
        }
    }

    return (
        <Wrapper pressed={pressed} disabled={disabled} onTouchStart={()=>{press(true)}} onTouchEnd={completeAction} onTouchMove={detectLossOfTarget}>{children}</Wrapper>
    )
}

export default Button;