import React, {useState} from 'react';
import { useSpring, useTrail, useTransition, animated, interpolate } from 'react-spring'
import styled from 'styled-components'

const Icon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12"><g transform="translate(0, 0)"><path d="M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z" fill="#000000"></path></g></svg>
}


const fast = { tension: 1000, friction: 100 }
const slow = { mass: 10, tension: 200, friction: 50 }
const trans = (xy, s) => `translate3d(${xy[0]}px,${xy[1]}px,0) scale(${s}) translate3d(-50%,-50%,0)`

const CursorElement = styled.div`
    height: 33px;
    width: 33px;
    border-radius: 50%;
    /* border: 1px solid black; */
    /* background: rgba(0,0,0,0.33); */
    opacity: 0.66;
    position: absolute;
    will-change: transform;
    z-index: 10;
    pointer-events: none;
    transform-origin: 0% 0%;
    box-shadow: 0px 3px 10px rgba(45, 45, 45, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    /* filter: invert(100%); */
`
const AnimatedCursor = animated(CursorElement);

const Cursor = ({activeCursor, cursorPosition}) => {

    // const [cursorSize, setSize] = useSpring(({activeCursor}) => ({ 
    //     value: activeCursor ? 2 : 1,
    //     config: { tension: 1000, friction: 100 } })
    // );

    const size = useSpring({scale: activeCursor ? 0.33 : 1})
    const background = useSpring({color: activeCursor ? 'gold' : 'rgba(0,0,0,0.33)'})

    // const [trail, set] = useTrail(1, () => ({ xy: [0, 0], config: i => (i === 0 ? fast : slow) }));
    {/* {trail.map((cursorPosition, index) => (
        <AnimatedCursor key={index} style={{ transform: cursorPosition.xy.interpolate(trans) }} />
    ))} */}
    return (
        <AnimatedCursor style={{ background: background.color , transform: interpolate([cursorPosition.xy, size.scale], (xy, s) => {return trans(xy, s)}) }}>
            {/* <Icon></Icon> */}
        </AnimatedCursor>
    )
}

export default Cursor;