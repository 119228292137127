import React, { memo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const Aside = styled.aside`
    filter: ${props => props.defocus ? 'blur(5px)' : 'none'};
`
const Medal = styled.div`
    display: inline-block;
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
    background: ${props => (props.placement==0) ? '#FEE101' : ((props.placement==1) ? '#D7D7D7' : (props.placement==2) ? '#A77044' : 'none') };
`

const Table = styled.div`

    & + & {
        margin-top: 1rem;
    }

    box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.5), inset -1px -1px 1px 0px rgba(255,255,255,0.05);
    
    background: ${props => props.active ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.1)'};
    border: 1px solid #467057;
    border: 0px solid #06180D;
    border-radius: 6px;
    padding: 1.5rem 1rem;
    box-sizing: border-box;

    & > div > div {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    & > div > div > div:first-child {
        text-align: left;
    }
    & > div > div > div:last-child {
        text-align: right;
    }

    & > div:first-child {
        font-weight: 600;
    }

    & > div:last-child {
        font-size: 0.8rem;
        display: ${props => props.active ? 'flex' : 'none'};
        flex-direction: column-reverse;
        & > div {
            height: 1.5rem;
            line-height: 1.5rem;
        }
        & > div:last-child {
            border-top: 1px solid #214731;
            padding-top: 1rem;
            margin-top: 1rem;
        }
        & > div + div {
            margin-bottom: 0.33rem;
        }
    }
`

const Scoreboard = memo(({defocus, players, player, setPlayer, names}) => {
    console.log('Rerendered');
    return (
        <Aside defocus={defocus}>
            {players.map((p, i) => {
                const scores = players.map((v)=>{
                    return _.sum(v)
                }).sort((a,b)=>{return b-a});
                const score = _.sum(p);
                const placement = _.indexOf(scores, score)
                return (
                    <Table active={player == i} onClick={()=>setPlayer(i)}>
                        <div>
                            <div>
                                <div><Medal placement={placement}/>{names[i]}</div>
                                <div>{_.sum(p)}</div>
                            </div>
                        </div>
                        <div>
                            {p.map((round, r) => {
                                const displayText = ((score) => {
                                    if (score == 0) {
                                        return `F`
                                    } else if (score > 0) {
                                        return `+${score}`
                                    } else {
                                        return score
                                    }
                                })(round);
                                return (
                                    <div>
                                        <div>Round {r+1}</div>
                                        <div>{displayText}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </Table>
                )
            })}
        </Aside>
    )
},   (prevProps, nextProps) => { return _.isEqual(prevProps.players, nextProps.players) && prevProps.player == nextProps.player })

export default Scoreboard;