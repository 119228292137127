import { useState, useEffect, useContext, memo } from 'react';

import { SanityContext } from '../contexts/sanity';
import { LocaleContext } from '../contexts/locale-context';

const useSanity = (query, params = {}) => {

    const { locale } = useContext(LocaleContext);
    const client = useContext(SanityContext);
    
    const parameters = {
        ...params,
        language: locale
    }

    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(true);
     
    useEffect(() => {
        setLoading(true);
        client(query, parameters).then((result) =>{
            setResult(result)
            setLoading(false);
        })
        .catch(err => {
          console.error('Oh no, the update failed: ', err.message)
        })
    }, [params.slug, locale]);

    return [result, loading];

}

export default useSanity;