import React, { useContext } from 'react'
import {Link} from 'react-router-dom'
import { LocaleContext } from './contexts/locale-context'

const CursorLink = ({onClick, ...rest}) => {
    const { locale, locales, changeLocale, activateCursor } = useContext(LocaleContext);
    return <Link onClick={()=>{onClick && onClick(); activateCursor(false);}} {...rest}>{rest.children}</Link>
    // return <Link onMouseEnter={()=>{activateCursor(true)}} onMouseLeave={()=>{activateCursor(false)}} onClick={()=>{onClick && onClick(); activateCursor(false);}} {...rest}>{rest.children}</Link>
}

export default CursorLink;