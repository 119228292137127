import React, { memo } from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import useSanity from './hooks/useSanity';

import LocalizationToggle from './LocalizationToggle';

const Wrapper = styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div > a {
        display: grid;
        grid-template-columns: 48px auto 32px;
        grid-template-rows: 48px;
        align-items: center;
        grid-gap: 1rem;
        padding: 1rem 0rem;
        &:active {
            background: rgba(145, 145, 145, 0.1);
        }
        & + a {
        border-top: 0.5px solid #e2e4e6;
        }
        & > img:nth-child(1) {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            line-height: 100%;
            text-align: center;
            object-fit: cover;
            border: 1px solid #e3e4e6;
            background: rgba(195, 195, 195, 0.1);
        }
        & > div:nth-child(2) {
        overflow: hidden;
        box-sizing: border-box;
        }
        & > div > div {
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            &:first-child {
                margin-bottom: 4px;
                & > span {
                    display: inline-block;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 700;
                    padding: 2px 4px;
                    margin-left: 4px;
                    background: rgb(255, 215, 100);
                    border-radius: 4px;
                    vertical-align: text-bottom;
                }
            }
        }
        &::after {
            opacity: 0.33;
            content: '';
            display: block;
            height: 12px;
            width: 12px;
            background: url(./images/chevron-right.svg);
            background-size: contain;
            justify-self: center;
        }
    }
`

const List = ({ clickHandler=(()=>{}) }) => {

    const query = `
        *[_type == "post"] {
            "title": coalesce(title[$language], title.en),
            slug,
            "imageUrl": mainImage.asset->url,
            author->
        }
    `
    const [entries, loading] = useSanity(query, {});
    
    return (
        <Wrapper>
            <div>
                {entries.map((v, i) => {
                    return (
                        <Link to={`/${v.slug.current}`} onClick={clickHandler}>
                            <img src={`${v.imageUrl}?h=96`} alt=""></img>
                            <div>
                                <div><b>{v.title}</b>{i<3 ? <span>New</span> : null}</div>
                                <div>By {v.author.name}. Read more about {v.title}.</div>
                            </div>
                        </Link>
                    )
                })}
            </div>
            <LocalizationToggle clickHandler={clickHandler}></LocalizationToggle>
        </Wrapper>
    )
}

export default List;