import React, { useState, useEffect, useReducer, useMemo } from 'react';
import styled from 'styled-components';

const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    border-radius: 2px;
    border-radius: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f5f5f5;
    background: #0A140E;
    border: 1px solid #050A07;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.33);
    padding: 4rem 6rem;
    text-align: center;
    & h1,
    & h2, 
    & p {
        font-weight: normal;
        margin: 0;
        padding: 0;
    }
    & h1 {
        font-family: 'Playfair Display';
        font-weight: 700;
        margin-bottom: 3rem;
    }
`
export default Modal;