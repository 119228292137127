import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';
import { SanityProvider } from './contexts/sanity_pa';
import { LocaleProvider } from './contexts/locale-context';
import useSanity from './hooks/useSanity_pa';
import {BrowserRouter as Router, Route, Link, Switch, useParams, useHistory, useLocation} from 'react-router-dom';
import { SiteSettingsProvider, SiteSettingsContext } from './contexts/siteSettings';
import { MenuStateProvider, MenuStateContext } from './contexts/pa-menu';
import { ScrollDirectionProvider, ScrollDirectionContext } from './contexts/pa-scroll-dir';

import Trail from './Trail'

import BlockContent from '@sanity/block-content-to-react';

import ProjectStream from './pa/project-stream'
import Project from './pa/project'
import Logo from './pa/logo'
import LogoSVG from './pa/logo-svg'
import HamburgerIcon from './pa/hamburger'
import Instagram from './icons/instagram'
import Stories from './pa/stories'

// import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger); 
// gsap.core.globals('ScrollTrigger', ScrollTrigger);

const serializers = {
  types: {
    video: (props) => {
      console.log('Props:', props.node.asset.url)
      return (
        <video width="100%" loop autoPlay muted>
            <source src={props.node.asset.url} type="video/mp4"/>
        </video>
      )
    }
  }
}


const Splash = styled.div`
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-image: url("${props => props.backgroundImage}?h=1920&fit=max");
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    height: 75px;
    @media only screen and (min-width: 769px) {
      height: 100px;
    }
    & path {
      fill: white;
    }
  }
`

const VideoWrapper = styled.div`
  height: calc(var(--vh, 1vh) * 70);
  width: 100%;
  overflow: hidden;
  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const PageHero = (props) => {
  return (
    props.hero ? (
      props.heroType == 'image' ? (
        <Hero halfHeight backgroundImage={props.heroImage}/>
      ) : (
        <VideoWrapper>
          <video width="100%" loop autoPlay muted>
              <source src={props.heroVideo} type="video/mp4"/>
          </video>
        </VideoWrapper>
      )
    ) : null
  )
}

const Hero = styled.div`
  height: calc(var(--vh, 1vh) * ${props => props.halfHeight ? 70 : 85});
  width: 100%;
  box-sizing: border-box;
  background-image: url("${props => props.backgroundImage}?h=1920&fit=max");
  background-size: cover;
  background-position: 50% 50%;
  border-left: ${props => props.accentColor ? `20px solid ${props.accentColor}` : 'none'};
`

const Button = styled.a`
  display: inline-block;
  color: #242424;
  background-color: #f5f5f5;
  padding: 20px 20px;
  text-transform: uppercase;
  position: relative;
  & > div.t
  & > div.b,
  & > div.l,
  & > div.r {
    position: absolute;
  }
  & > div.t {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 20px);
    border-top: 1px dashed #f5f5f5;
  }
  & > div.b {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 20px);
    border-top: 1px dashed #f5f5f5;
  }
  & > div.l {
    position: absolute;
    top:  50%;
    left: 0;
    transform: translateY(-50%);
    height: calc(100% + 20px);
    border-left: 1px dashed #f5f5f5;
  }
  & > div.r {
    position: absolute;
    top:  50%;
    right: 0;
    transform: translateY(-50%);
    height: calc(100% + 20px);
    border-left: 1px dashed #f5f5f5;
  }
`

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  background-color: #f5f5f5;
  min-height: 100%;
  footer {
    & > div {
      box-sizing: border-box;
      max-width: 500px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      
      & > div:nth-child(1) {
        a {
          display: inline-block;
          color: #242424;
          background-color: #f5f5f5;
          padding: 20px 20px;
          text-transform: uppercase;
          position: relative;
          & > div.t
          & > div.b,
          & > div.l,
          & > div.r {
            position: absolute;
          }
          & > div.t {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% + 20px);
            border-top: 1px dashed #f5f5f5;
          }
          & > div.b {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% + 20px);
            border-top: 1px dashed #f5f5f5;
          }
          & > div.l {
            position: absolute;
            top:  50%;
            left: 0;
            transform: translateY(-50%);
            height: calc(100% + 20px);
            border-left: 1px dashed #f5f5f5;
          }
          & > div.r {
            position: absolute;
            top:  50%;
            right: 0;
            transform: translateY(-50%);
            height: calc(100% + 20px);
            border-left: 1px dashed #f5f5f5;
          }
        }
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        @media only screen and (min-width: 769px) {
          align-items: flex-end;
          flex-direction: row;
        }
      }
      @media only screen and (min-width: 769px) {
        margin-right: 0;
      }
      & > div + div {
        padding-top: 3rem;
      }
    }

    
    background: #232323;
    color: white;
    width: 100%;
    box-sizing: border-box;
    padding: 8rem 0vw;
    
    @media only screen and (min-width: 769px) {
      padding: 8rem 20vw 8rem 0vw;
    }
    @media only screen and (min-width: 1025px) {
      padding: 8rem 20vw;
    }
    
    a {
      color: inherit;
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li + li {
        margin-top: 0.25rem;
      }
    }

    ul + ul {
      margin-top: 3rem;
    }

    @media only screen and (min-width: 769px) {
    }

    svg {
      height: 1.6rem;
    }


  }
`

const InputGroup = styled.div`
  display: flex;
  /* background: blue; */
  padding-bottom: 3rem;
  /* border-bottom: 1px solid gray; */
  /* border-radius: 12px; */
  overflow: hidden;
  button,
  input {
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    background: none;
    outline: none;
    height: 40px;
    padding: 0;
    margin: 0;
    color: white;
    font-family: inherit;
  }
  input {
    width: 100%;
    border-bottom: 1px solid white;
    ::-webkit-input-placeholder {
      color: white;
    }
    :-ms-input-placeholder {
      color: white;
    }
    ::placeholder {
      color: white;
    }
  }
  button {
    width: 100%;
    font-weight: bold;
    border: 1px solid white;
    margin-top: 2rem;
  }
  button:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  @media only screen and (min-width: 769px) {
    input {
      width: 60%;
    }
    button {
      width: 30%;
      margin-top: 0rem;
    }
  }
  @media only screen and (min-width: 1025px) {
  }

`

const Footer = ({children}) => {

  const siteContext = useContext(SiteSettingsContext);
  const companyInfo = _.find(siteContext?.result, {_type: 'companyInfo'});

  return (
    <FooterWrapper>
      {children}
      <footer>
        <div>
          {/* <InputGroup>
            <input type="text" placeholder="Email address"/>
            <button>Sign Up</button>
          </InputGroup> */}
          <div>
            <ul>
              <li>
                <b>{companyInfo?.name}</b>
              </li>
              <li>{companyInfo?.address1}</li>
              <li>{companyInfo?.city}, {companyInfo?.zipCode}</li>
            </ul>
            <ul>
              <li>
                <Button href={`mailto:${companyInfo?.email}`}>
                  <div className="l"></div>
                  <div className="r"></div>
                  <div className="t"></div>
                  <div className="b"></div>
                  Connect with us
                </Button>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <a href={`tel:${companyInfo?.phone}`}>
                  {companyInfo?.phone}
                </a>
              </li>
              <li>
                <a href={`mailto:${companyInfo?.email}`}>
                  {companyInfo?.email}
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://www.instagram.com/poiesis_architecture/">
                  <Instagram color="#fff"></Instagram>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </FooterWrapper>
  )
}




const LayoutA = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 0 auto;
  /* height: 100%; */
  /* border: 3px solid red; */
  & > div:nth-child(1) {
    grid-row: 2;
    /* border: 3px solid green; */
  }
  & > div:nth-child(2) {
  }
  & > ul:nth-child(2) {
    /* grid-row: 1; */
    /* margin: 0; */
    /* padding: 0; */
    /* list-style: none; */
    /* border: 3px solid blue; */
  }
  @media only screen and (min-width: 769px) {
    grid-template-columns: auto 225px;
    grid-template-columns: auto 20vw;
    /* grid-template-columns: auto minmax(0px, auto); */
    grid-template-rows: auto;
    & > div:nth-child(1),
    & > div:nth-child(2) {
      grid-row: 1;
    }
  }
`
const LayoutB = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  & > div:nth-child(1) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
  @media only screen and (min-width: 1025px) {
    grid-template-columns: 225px auto;
    grid-template-columns: 20vw auto;

    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      /* width: auto; */
      /* background-color: blue; */
    }
  }

`
const LayoutC = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  /* height: 100%; */
`

const SiteWrapper = styled.main`

  @font-face {
    font-family: "default";
    font-weight: normal;
    src: url(${props => props.font}) format("truetype");
  }  
  -webkit-tap-highlight-color: transparent;
  font-family: "default", sans-serif;
  /* letter-spacing: 0px; */
  letter-spacing: 0.25px;
  line-height: 1.5;
  color: #767676;
  color: #535353;
  color: #232323;
  /* color: #3d3d3d; */
  /* color: gray; */
  height: 100%; 
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  p {
    font-family: 'Nunito Sans', 'default';
    font-weight: 300;
    /* word-spacing: 0px; */
  }

`

const Providers = () => {
  return (
    <LocaleProvider>
      <SanityProvider>
        <SiteSettingsProvider>
          <MenuStateProvider>
            <ScrollDirectionProvider>
              <PA></PA>
            </ScrollDirectionProvider>
          </MenuStateProvider>
        </SiteSettingsProvider>
      </SanityProvider>
    </LocaleProvider>
  )
}

const PA = () => {

  const scrollContext = useContext(ScrollDirectionContext);
  const [scrollDir, setScrollDir] = useState("scrolling up");
  const innerRef = useRef(null);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const div = innerRef.current;

    const updateScrollDir = () => {
      const scrollY = div.scrollTop;
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        if (scrollY > 0*window.innerHeight) {
          setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    div.addEventListener("scroll", onScroll);
    return () => div.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    scrollContext.setScrollDirection(scrollDir);
  }, [scrollDir]);

  const siteContext = useContext(SiteSettingsContext);
  const siteSettings = _.find(siteContext?.result, {_type: 'siteSettings'});
  const companyInfo = _.find(siteContext?.result, {_type: 'companyInfo'});

  const scrollToTop = () => {
    const div = innerRef.current;
    div.scrollTop = 0;
  }

  const scrollTo = (to, duration) => {
    const element = innerRef.current;
    if (duration <= 0) {return;}
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;
    
    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) {return;}
      scrollTo(to, duration - 10);
    }, 10);
  }

    return (
      <SiteWrapper font={siteSettings?.font} ref={innerRef} >
          <Router>
              <Switch>
                  <Route path="/" exact render={()=>(
                    <Home logo={siteSettings?.logo} siteSettings={siteSettings} companyInfo={companyInfo} backgroundImage={siteSettings?.slides}/>
                  )}></Route>
                  <Route path="/projects" exact render={()=> (
                    <Projects st={scrollTo} stt={scrollToTop} logo={siteSettings?.logo} backgroundImage={siteSettings?.slides}/>
                  )}></Route>
                  <Route path="/projects/:slug" exact  render={()=>(
                    <ProjectPage st={scrollTo} stt={scrollToTop} logo={siteSettings?.logo}/>
                  )}></Route>
                  <Route path="/practice" exact  render={()=>(
                    <About page="practice" st={scrollTo} stt={scrollToTop} logo={siteSettings?.logo}/>
                  )}></Route>
                  <Route path="/studio" exact  render={()=>(
                    <About page="studio" st={scrollTo} stt={scrollToTop} logo={siteSettings?.logo}/>
                  )}></Route>
                  <Route path="/passive-design" exact  render={()=>(
                    <About page="passive-design" st={scrollTo} stt={scrollToTop} logo={siteSettings?.logo}/>
                  )}></Route>
                  <Route component={FourZeroFour}></Route>
              </Switch>
          </Router>
      </SiteWrapper>
    )
}

const FourZeroFour = () => {
  return <div>404 Page Not Found</div>
}


const appear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const TextLayout = styled.div`
  animation: ${appear} 0.15s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
  padding: calc(var(--vh,1vh) * 10) 0rem 8rem;
  width: 90%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  figure {
    margin: 0;
    img {
      width: 100%;
    }
  }
  @media only screen and (min-width: 769px) {
    margin-right: unset;
    padding: 15vh 0rem 8rem;
  }
  h1:nth-child(1) {
    margin-top: 0;
  }
`

const getPropsByPrefix = (prefix, o) => Object.fromEntries(Object.entries(o).filter(([k, v]) => k.startsWith(prefix)))

const About = ({logo, page, stt}) => {
  
  // let { slug } = useParams();
  // const [params, setParams] = useState({slug: slug})

  // useEffect(() => {
  //   setParams({slug: slug})
  // }, [slug]);

  const query = `
    *[_id == "${page}"][0] {
      _id,
      title,
      body[] {
        ...,
        asset->{
          ...
        }
      },
      hero,
      heroType,
      "heroImage": heroImage.asset->url,
      "heroVideo": heroVideo.asset->url
    }
  `
  const {result, loading} = useSanity(query, {}, page);

  useEffect(()=>{
    stt();
  }, [loading]);

  console.log(result.heroVideo)

  return (
    <Footer>
      <LayoutA>
        <LayoutC>
            <PageHero {...getPropsByPrefix('hero', result)}/>
            <LayoutB>
              <Logo logo={logo}></Logo>
              {loading ? null : 
                <TextLayout>
                  <BlockContent serializers={serializers} blocks={result.body}></BlockContent>
                </TextLayout>
              }
            </LayoutB>
        </LayoutC>
          <Menu/>
          {/* <Menu logo={logo}/> */}
      </LayoutA>
    </Footer>
  )
}

const Home = ({logo, backgroundImage, siteSettings, companyInfo}) => {
  return (
    <Footer>
      <LayoutA>
          <LayoutC>
              <Stories backgroundImage={backgroundImage}/>
              <LayoutB>
                  <Logo logo={logo}></Logo>
              </LayoutB>
          </LayoutC>
          <Menu/>
      </LayoutA>
    </Footer>
    // <p>We are developing our new site at p-arch.ca.</p>
    // <li><a href={`mailto:${companyInfo?.email}`}>{companyInfo?.email}</a></li>
  )
}

const grow = keyframes`
    0% {
        width: 0px
    }
    100% {
        width: 20vw;
    }
`

const Nav = styled.div`
  /* border: 1px solid red; */
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  /* width: 0px;
  animation: ${grow} 1s ease-in;
  animation-fill-mode: forwards;
  animation-delay: 4s; */
  & > div {
    transform: ${props=> props.visible ? 'translateY(0%)' : props.scrollDir == 'scrolling down'  ? 'translateY(-100%)' : 'translateY(0%)'};
    transition: transform 0.33s;
    background: rgb(245, 245, 245, 0.85);
    backdrop-filter: blur(6px);
    /* display: flex; */
    flex-direction: column;
    align-items: flex-end;
    position: sticky;
    top: 0;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    /* min-height: calc(var(--vh, 1vh) * 10); */
    /* padding: 1.33rem 0rem; */
  }
  & > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    /* border: 1px solid blue; */
    box-sizing: border-box;
    /* min-height: ${props=> props.visible ? 'calc(var(--vh, 1vh) * 100)' : 'calc(var(--vh, 1vh) * 10)'}; */
    min-height: calc(var(--vh, 1vh) * 10);
  }
  svg:nth-child(1) {
    height: 32px;
  }
  svg:nth-child(2) {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }
  nav {
    /* display: ${props=>props.visible ? 'block' : 'none'}; */
  }
  ul {
    /* border: 1px solid red; */
    box-sizing: border-box;
    height: ${props=> props.visible ? 'calc(var(--vh, 1vh) * 90)' : 'calc(var(--vh, 1vh) * 0)'};
    transition: all 0.33s;
    overflow: hidden;

  }

  @media only screen and (min-width: 769px) {
    & > div > div {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: calc(var(--vh, 1vh) * 15);
      display: none;
    }
    svg:nth-child(1) {
      display: none;
    }
    & > div {
      min-height: ${props=> props.visible ? 'calc(var(--vh, 1vh) * 100)' : 'calc(var(--vh, 1vh) * 15)'};
      min-height: calc(var(--vh, 1vh) * 100);
      min-height: auto;
      /* min-height: calc(var(--vh, 1vh) * 15); */
      transform: none;
      /* border: 1px solid blue; */
      /* padding-right: 1rem; */
    }
    ul {
      height: ${props=> props.visible ? 'calc(var(--vh, 1vh) * 85)' : 'calc(var(--vh, 1vh) * 0)'};
      height: calc(var(--vh, 1vh) * 85);
      height: auto;
      /* border: 1px solid red; */
    }
  }
  @media only screen and (min-width: 1024px) {
  }
`

const MenuWrapper = styled.nav`
  width: 100%;
  box-sizing: border-box;
  margin: 0rem auto 0rem;
  ul {
    /* text-transform: uppercase; */
    font-size: 2.33rem;
    font-weight: bold;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    list-style: none;
    padding: 0;
    text-align: right;
    width: 100%;
    margin: 0;
    li {
      margin: 3rem 5vw 0rem 0rem;
      a {
        display: inline-block;
        position: relative;
        /* border-bottom: 3px solid blue; */
        &::after {
          content: '';
          display: block;
          width: 0%;
          height: 1px;
          background: rgba(83, 83, 83, 1);
          transition: all 0.33s;
        }
      }
      a[href="${props=> props.selected}"] {
        &::after {
          width: 100%;
          background: rgba(83, 83, 83, 0.3);
        }
      }
      a:hover {
        &::after {
          width: 100%;
          background: rgba(83, 83, 83, 1);
        }
      }
    }
    li + li {
      margin: 1rem 5vw 0rem 0rem;
    }
    li:last-of-type {
      margin-top: 4rem;
      a::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: rgba(83, 83, 83, 1);
      }
    }
    a { 
      color: inherit;
      text-decoration: none;
    }
  }
  @media only screen and (min-width: 769px) {
    ul {
      font-size: 1rem;
      text-align: left;
      justify-content: left;
      li {
        margin: 15vh 0rem 0rem 4rem;
      }
      li + li {
        margin: 1rem 0rem 0rem 4rem;
      }
    }
  }
`
const MenuContent = () => {
  const menuContext = useContext(MenuStateContext);
  const location = useLocation();

  const siteContext = useContext(SiteSettingsContext);
  const companyInfo = _.find(siteContext?.result, {_type: 'companyInfo'});

  const MenuEntries = [
    {
      route: '/projects',
      label: 'Projects'
    },
    {
      route: '/passive-design',
      label: 'Passive Design'
    },
    {
      route: '/practice',
      label: 'Practice'
    }
  ];

  return (
    <MenuWrapper selected={location.pathname}>
      <ul>
        {MenuEntries.map((entry)=>{
          return (
            <li>
              <Link onClick={()=>{ menuContext.makeMenuVisible(false) }} to={entry.route}>{entry.label}</Link>
            </li>
          )
        })}
        <li>
          <a href={`mailto:${companyInfo?.email}`}>
            Connect with Us
          </a>
        </li>
      </ul>
    </MenuWrapper>
  )
}

const Spacer = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 85);
`

const Menu = ({scroll, logo}) => {

  const scrollContext = useContext(ScrollDirectionContext);
  const menuContext = useContext(MenuStateContext);

  return useMemo(()=> {
    return (
      <Nav scrollDir={scrollContext.scrollDirection} visible={menuContext.menuVisible}>
        <div>
          <div>
            <Link to="/projects">
              <LogoSVG></LogoSVG>
            </Link>
            <HamburgerIcon customClickHandler={()=>{
              // scroll(0.9*window.innerHeight, 333);
              menuContext.makeMenuVisible(!menuContext.menuVisible)}} menuVisible={menuContext.menuVisible}/>
          </div>
          <MenuContent st={scroll}/>
        </div>
      </Nav>
    )
  }, [scrollContext.scrollDirection, menuContext.menuVisible])
}

const Projects = ({st, stt, logo, backgroundImage}) => {

  const query = `
    *[_type == "project"] {
      _id,
      title,
      "href": slug.current,
      "mainImage": mainImage.asset->url,
      accentColor,
      excerpt
    }
  `
  var metaThemeColor = document.querySelector("meta[name=theme-color]");
  metaThemeColor.setAttribute("content", "white");

  const {result, loading} = useSanity(query);

  const siteContext = useContext(SiteSettingsContext);
  const siteSettings = _.find(siteContext?.result, {_type: 'siteSettings'});
  
  useEffect(()=>{
    stt();
  }, [loading]);

  return (
    <Footer>
      <LayoutA>
        <LayoutB>
          <div>
            <Spacer/>
            <Logo logo={logo}></Logo>
          </div>
          {loading ? null : <ProjectStream projects={siteSettings?.projects}></ProjectStream>}
        </LayoutB>
        <Menu scroll={st} logo={logo}/>
      </LayoutA>
    </Footer>
  )
}

const ProjectPage = ({stt, logo}) => {

  let { slug } = useParams();
  const [params, setParams] = useState({slug: slug})

  useEffect(() => {
    setParams({slug: slug})
  }, [slug]);

  const query = `
    *[_type == "project" && slug.current==$slug][0] {
      _id,
      slug,
      title,
      excerpt,
      "mainImage": mainImage.asset->url,
      accentColor,
      area,
      location,
      startedAt,
      endedAt,
      publishedAt,
      members,
      categories[]->{
        title
      },
      body,
      gallery[] {
        alt,
        "imageUrl": asset->url
      },
      relatedProjects
    }
  `
  const {result, loading} = useSanity(query, params);

  useEffect(()=>{
    stt();
  }, [loading])

  useEffect(()=>{
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", result?.accentColor || "white");
  }, [result])

  return (
    <Footer>
      <LayoutA>
          <LayoutC>
              <Hero accentColor={result?.accentColor || "white"} backgroundImage={result.mainImage}/>
              <LayoutB>
                  <Logo logo={logo}></Logo>
                  <Project {...result}></Project>
              </LayoutB>
          </LayoutC>
          <Menu/>
      </LayoutA>
    </Footer>
  )
}

export default Providers;