import React from 'react';
import sanityClient from '@sanity/client';

const SanityContext = React.createContext();

const SanityProvider = ({ children }) => {
    
  const client = sanityClient({
    projectId: '3f1s05o6',
    dataset: 'production',
    token: 'skpKboLZVsmot5tnQFpKb6VOtgAzv0juy3qgabT8wyLiWfgK35MAOvgCiGBfZ4RsPhEk88IGjpv64OY6g1kLBHciXPBmvhijT9hBsoehHGBWocsoWSEUIIYxfvrKU2xxmirkq9rwl0HtTBIdfQ29uIradpwFBztARaR0BbGmIMxVWwLsppB4',
    useCdn: false,
    ignoreBrowserTokenWarning: true
  })

  async function getData (query, params) {
    return await client.fetch(query, params);
  }

  return (
    <SanityContext.Provider value={getData}>
      {children}
    </SanityContext.Provider>
  )

}

export { SanityProvider, SanityContext };