import React from 'react'
import styled from 'styled-components'

const strokeStyle = { vectorEffect: 'non-scaling-stroke' }

const AnimatedHamburger = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  path {
    stroke: gray;
    transition: transform 250ms;
  }
  path:nth-child(1) {
    transform-box: view-box;
    transform-origin: 50% 50%;
    transform: ${props => props.menuVisible ? 'rotateZ(45deg)' : 'rotateZ(0deg) translateY(-3px)'};
  }
  path:nth-child(2) {
    transform-box: view-box;
    transform-origin: 50% 50%;
    transform: ${props => props.menuVisible ? 'rotateZ(-45deg)' : 'rotateZ(0deg) translateY(3px)'};
  }
`

const HamburgerIcon = ({ menuVisible, customClickHandler }) => (
  <AnimatedHamburger
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
    menuVisible={menuVisible}
    onClick={customClickHandler}
  >
    <path d='M0 10H20' stroke='currentColor' strokeWidth='1.5' style={strokeStyle} />
    <path d='M0 10H20' stroke='currentColor' strokeWidth='1.5' style={strokeStyle} />
  </AnimatedHamburger>
)

export default HamburgerIcon;