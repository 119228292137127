import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Providers from './PA';
import * as serviceWorker from './serviceWorker';
import {createGlobalStyle} from 'styled-components'
import { ScrollDirectionProvider, ScrollDirectionContext } from './contexts/pa-scroll-dir';
import { LocaleProvider } from './contexts/locale-context';

const GlobalStyles = createGlobalStyle`
  :root {
    --vh: ${window.innerHeight * 0.01}px;
  }
`

//   <GlobalStyles />

ReactDOM.render(
  // <React.StrictMode>
    <ScrollDirectionProvider>
      <LocaleProvider>
        {/* <App></App> */}
        <Providers />
      </LocaleProvider>
    </ScrollDirectionProvider>
  // </React.StrictMode>,
  ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
