import React, { useState } from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const Wrapper = styled.section`
    ${({orientation}) => orientation === 'horizontal' && `
        grid-template-columns: repeat(3, minmax(0, 420px));
    `}
    display: grid;
    justify-content: center;
    margin: 3rem auto;
    & > div {
        display: grid;
        justify-items: center;
        align-items: center;
        padding: 2rem 1rem;
        grid-gap: 5rem;

        &:nth-child(odd),
        &:nth-child(even) {
            grid-template-columns: minmax(0, auto);
            grid-template-rows: minmax(0, auto) minmax(0, auto);
        }

        @media only screen and (min-width: 768px) {
            padding: 4rem 2rem;
            &:nth-child(odd) {
                grid-template-columns: minmax(0, 50vw) minmax(0, 30vw);
            }
            &:nth-child(even) {
                grid-template-columns: minmax(0, 30vw) minmax(0, 50vw);
                & > div:nth-of-type(1) {
                    order: 2;
                }
            }
        }
        
        & > div:nth-of-type(2) {
            border-radius: 5px;
            overflow: hidden;
            & > img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                opacity: 0.85;
            }
        }

        & > div:nth-of-type(1) {
            position: relative;
        }
        & > div:nth-of-type(1) > h1 {
            font-size: 0.95rem;
            margin-bottom: 0.5rem;
            color: #0f886f;
            color: #353535;
            ;
        }
        & > div:nth-of-type(1) > div:nth-of-type(2) {
            margin-top: 2rem;
            display: grid;
            grid-gap: 2rem;

            grid-template-columns: minmax(0, auto);
            grid-template-rows: minmax(0, auto) minmax(0, auto);
            & > div {
                width: 100%;
                /* border-left: 2px solid #545454; */
                /* padding-left: 1rem; */
            }
            & > div:nth-child(1) {
                justify-self: center;
            }
            & > div:nth-child(2) {
                justify-self: center;
            }
            @media only screen and (min-width: 420px) {
                grid-template-columns: minmax(0, 50%) minmax(0, 50%);
                & > div {
                    width: 100%;
                    border-color: transparent;
                }
                & > div:nth-child(1),
                & > div:nth-child(2) {
                    justify-self: flex-start;
                }
            }
            
            & p {
                font-size: 14px;
            }
        }
    }
`

const ContentGroupAltWidget = ({groups}) => {
    return <Wrapper>
        {groups.map((cg) => {
            return (
                <div>
                    <div>
                        <h1>{cg.tag}</h1>
                        <BlockContent blocks={cg.mainContent}></BlockContent>
                        <div>
                            <BlockContent blocks={cg.columnA}></BlockContent>
                            <BlockContent blocks={cg.columnB}></BlockContent>
                        </div>
                    </div>
                    <div>
                        <img src={cg.image}></img>
                    </div>
                </div>
            )
        })}
    </Wrapper>
}

export default ContentGroupAltWidget;