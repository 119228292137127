import React from 'react';
import styled, {keyframes, css} from 'styled-components';
import {useLocation} from 'react-router-dom';
import Link from './Link';
import {useParams} from 'react-router-dom';
import useSanity from './hooks/useSanity';
import Close from './icons/close'
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';

import LocalizationToggle from './LocalizationToggle'

function Icon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" x="0" y="0" viewBox="0 0 16 16">
        <path fill="#444"d="M8 12a.997.997 0 01-.651-.241L-.41 5.109.892 3.59 8 9.683l7.108-6.093 1.302 1.519-7.759 6.65A.997.997 0 018 12z"></path>
      </svg>
    );
}

const appear = (direction) => keyframes`
    0% {
        transform: ${direction[0] == -1 ?
            'translate(0%, 20px)':
                direction[0] < direction[1] ?
            'translate(-20px, 0%)' : 'translate(20px, 0%)'};
        pointer-events: none;
        opacity: 0;
    }
    100% {
        pointer-events: all;
        transform: translate(0%, 0%);
        opacity: 1;
    }
`

const Wrapper = styled.nav`

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    transform: ${({scrollDir}) => scrollDir=='scrolling up' ? 'translateY(0)' : 'translateY(-100%) translateY(-1px)' };
    /* opacity: ${({scrollDir}) => scrollDir=='scrolling up' ? 1 : 0 }; */
    transition: all 0.33s;
    /* box-shadow: 0px 3px 10px rgba(45, 45, 45, 0.15); */

`

const Super = styled.section`
    background: #242424;
    background: white;
    /* color: white; */
    font-size: 0.8rem;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem 2rem;
    & > ul {
        display: flex;
        margin: 0;
        padding: 0;
        & > li {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
`
const Main = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 65px;
    width: 100%;
    background: white;
    border-bottom: 1px solid #dbdbdb;

    & > div {
        display: grid;
        padding: 0rem 2rem;
        /* cursor: pointer; */
        height: 65px;
        & > a {
            height: 100%;
            display: flex;
            align-items: center;
        }
        & > a,
        & > span {
            align-self: center;
        }
    }

    & > div > span > svg {
    
    }
`
const Tray = styled.section`
    background: white;
    background: rgba(0, 0, 0, 0.95);
    transition: all 0.33s;
    &:hover {
        background: rgba(0, 0, 0, 1);
    }
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Close Button */
    & > span {
        display: none;
        position: absolute;
        width: 100%;
        text-align: center;
        padding: 2rem 0rem 1rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 12px;
    }
    

    @media only screen and (min-width: 768px) {
        & > span {
            display: none;
        }
    }
`

const SubNav = styled.ul`
    overflow: hidden;
    max-width: 1280px;
    & img {
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
        margin-top: 2rem;
    }

    & li {
        animation: ${({direction}) => appear(direction)} 0.33s ease-out;
        animation-fill-mode: forwards;
        animation-delay: ${({direction}) => direction[0] == -1 ? '0.3s' : '0.1s'};
        opacity: 0;
        width: 80%;
    }

    & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        /* border: 1px solid red; */
        flex-direction: column;
        & > li {
            /* border: 1px solid blue; */
            padding: 2rem;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            &:hover {
                color: #f5f5f5;
            }
        }
        @media only screen and (min-width: 768px) {
            flex-direction: column;
            width: 25%;
        }
        & span {
            display: block;
            font-weight: 600;
            margin: 0rem 0rem 0.5rem;
        }
        & span + p {
        }
    }

    height: ${({shown})=>{ return shown ? 'calc(var(--vh, 1vh) * 100 - 65px)' : '0px'}};
    transition: height 0.66s;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom: ${({shown})=>{ return shown ? '1px solid #dbdbdb' : '0px solid #dbdbdb;'}};

    /* & > * {
        border: 1px solid red;
        & > * {
            border: 1px solid blue;
        }
    } */
    @media only screen and (min-width: 768px) {
        /* justify-content: center; */
        flex-direction: row;
        height: ${({shown})=>{ return shown ? '400px' : '0px'}};
        transition: height 0.33s;
    }
`



function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

const NavItem = styled.div`
    /* font-weight: 600; */
    & > span > svg {
        height: 8px;
        width: 8px;
        position: relative;
        display: inline-block;
        top: 0px;
        margin-left: 8px;
        transition: all 0.33s;
        transition-delay: 0s;
        transform: ${({flip}) => flip ? 'scaleY(-1)' : 'scaleY(1)'};
    }
`

const Nav = ({ scrollDir }) => {

    const query = `
        *[_type == "siteSettings"] {
            "title": coalesce(title[$language], title.en),
            navigation[]-> {
                _type == 'navigationItem' => {
                    _id,
                    _type,
                    link {
                        "text":  coalesce(text[$language], text.en),
                        destination
                    },
                    "icon": icon.asset->url
                },
                _type == 'post' => {
                    _id,
                    _type,
                    "link": {
                        "text": coalesce(title[$language], title.en),
                        "destination": slug.current
                    },
                    "icon": icon.asset->url
                },
                _type == 'navigationItemGroup' => {
                    _id,
                    _type,
                    "title": coalesce(title[$language], title.en),
                    "children": links[]-> {
                        _type == 'navigationItem' => {
                            _id,
                            _type,
                            link {
                                "text": coalesce(text[$language], text.en),
                                destination
                            },
                            "icon": icon.asset->url
                        },
                        _type == 'post' => {
                            _id,
                            _type,
                            "link": {
                                "text": coalesce(title[$language], title.en),
                                "destination": slug.current
                            },
                            "icon": icon.asset->url
                        }
                    }
                }

            }
        }
    `;
    
    const [appData, loading] = useSanity(query, {});
    console.log(appData)

    const [trayContent, setTrayContent] = useState({
        content: [],
        direction: 'up',
        lastOpened: -1
    });

    const prevContent = usePrevious(trayContent.lastOpened);

    const openTray = useCallback((content, i) => {
        setTrayContent(oldContent => {
            if (i == oldContent.lastOpened) {
                return oldContent
            } else {
                return {
                    ...oldContent,
                    lastOpened: i,
                    content: content
                }
            }
        })
    }, [prevContent])

    const closeTray = useCallback(() => {
        setTrayContent(oldContent => {
            return {
                ...oldContent,
                lastOpened: -1,
                content: []
            }
        });
    }, []);

    // return useMemo(() => {
        return (
            <>
                <Wrapper onMouseLeave={closeTray} scrollDir={scrollDir}>
                    <Super>
                        <ul>
                            <li><LocalizationToggle/></li>
                        </ul>
                        <ul>
                            <li>Contact</li>
                        </ul>
                    </Super>
                    <Main>
                        {!loading && appData[0].navigation.map((v, i) => {
                            if (v._type == "navigationItemGroup") {
                                return (
                                    <NavItem onMouseOver={() => {openTray(v.children, i)}} key={v._id} flip={trayContent.lastOpened == i}>
                                        <span>{v.title}<Icon/></span>
                                    </NavItem>
                                )
                            };
                            return (
                                <NavItem key={`${v._id}`} onClick={closeTray}>
                                    <Link to={v.link.destination}>
                                        <span>{v.link.text}</span>
                                    </Link>
                                </NavItem>
                            )
                        })}
                    </Main>
                    <Tray>
                        {trayContent.content.length ? <span onClick={closeTray}>Close</span> : null}
                        <SubNav shown={trayContent.content.length > 0} direction={[prevContent, trayContent.lastOpened]}>
                            {trayContent.content.map((u,j)=>{
                                return (
                                    <Link to={u.link.destination} key={`${u._id}${j}`} onClick={closeTray}>
                                        <li>
                                            {/* <img src={u.icon}></img> */}
                                            <img src="https://cdn.sanity.io/images/403afmyn/production/e70c17e13876cee41097ea9dfb728f49cc60ae33-2048x1365.png"></img>
                                            <div>
                                                <span>{u.link.text}</span>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                            </div>
                                        </li>
                                    </Link>
                                )
                            })}
                        </SubNav>
                    </Tray>
                </Wrapper>
            </>
        )
    // }, [scrollDir, trayContent, appData, loading])
    
}

export default Nav