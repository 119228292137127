import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react';

const TextLayout = styled.div`
  padding: 0rem 0rem 8rem;
  width: 90%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 769px) {
    margin-right: unset;
  }
  h2 {
    margin: 4rem 0rem 4rem;
  }
`

const Details = styled.aside`
  width: 90%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 1rem;
  width: 90%;
  margin: 0 auto;
  height: 15vh;
  height: calc(var(--vh, 1vh) * 15);
  padding: 1rem 0rem;
  align-items: center;
  & > div {
    align-self: middle;
  }
  & > div:nth-child(1) {
    width: 100%;
    font-size: 1.5rem;
  }
  @media only screen and (min-width: 769px) {
    padding: 0rem;
    max-width: unset;
    width: 100%;
    justify-content: space-evenly;
    & > div {
      font-size: 1rem;
    }
    & > div:nth-child(1),
    & > div {
      width: auto;
    }
  }
  @media only screen and (min-width: 1025px) {
  }
`

const Gallery = styled.div`
  text-align: right;
  width: 90%;
  max-width: 500px;
  img {
    display: block;
    width: 100%;
  }
  img + img {
    margin-top: 160px;
  }
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 80px;
  @media only screen and (min-width: 769px) {
    margin-right: unset;
    max-width: 800px;
  }
`

const RelatedProjects = styled.div`

  width: 90%;
  max-width: 500px;
  margin-left: auto;
  margin-right: 0;
  padding: 0rem 0rem 3rem;

  ul {
    list-style: none;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }
  li + li {
    margin-top: 1.5rem;
  }
  ul > li {
    display: grid;
    grid-template-columns: 60px auto;
    grid-gap: 1rem;
    align-items: center;
  }
  ul > li > div:nth-child(1) {
    height: 60px;
    width: 60px;
    background: #dbdbdb;
  }

  ul > li > div > h3 {
    margin: 1rem 0rem;
  }

  img {
    width: 200px;
  }

`

function project (props) {
  const { body, title, excerpt, gallery, area, location, categories, endedAt } = props;
  return (
    <article>
        <Details>
            <div>{title}</div>
            <div>{categories ? categories[0].title : '-'}</div>
            <div>{location ? location : '-'}</div>
            <div>{endedAt ? endedAt.slice(0,4) : 'In Progress'}</div>
        </Details>
        <TextLayout>
            <h2>{excerpt}</h2>
            {body && <BlockContent blocks={body || []} />}
        </TextLayout>
        <Gallery>
            {gallery?.map((image, key) => {
                return image.imageUrl && <img key={`img${key}`} src={`${image.imageUrl}?h=1024&fit=max`} alt={image.alt} />
            })}
        </Gallery>
        {/* <RelatedProjects>
          <h2>Related Projects</h2>
          <ul>
            <li>
              <div></div>
              <div>
                <h3>Related Project</h3>
              </div>
            </li>
            <li>
              <div></div>
              <div>
                <h3>Related Project</h3>
              </div>
            </li>
            <li>
              <div></div>
              <div>
                <h3>Related Project</h3>
              </div>
            </li>
          </ul>
        </RelatedProjects> */}
    </article>
  )
}

export default project
