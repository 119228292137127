import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Icon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"><g transform="translate(0, 0)"><path d="M14.619,23a1.05,1.05,0,0,1-.938-.579L10.572,16.2a1.052,1.052,0,0,1,.075-1.066l5.02-6.8-6.8,5.018a1.05,1.05,0,0,1-1.065.075L1.579,10.318A1.048,1.048,0,0,1,1.685,8.4L21.59,1.064a1.047,1.047,0,0,1,1.345,1.345L15.6,22.315a1.046,1.046,0,0,1-.925.683Z" fill="#444444"></path></g></svg>
}

const Wrapper = styled.div`
    width: 320px;
    height: 600px;
    margin: 2rem;
    background: white;
    border-radius: 32px;
    overflow: hidden;
    border: 6px solid #242424;
    display: grid;
    grid-template-rows: 56px 1fr 60px;
    float: left;
`

const Users = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-evenly;
    align-items: center;
    li {
        list-style: none;
        height: 32px;
        width: 32px;
        border-radius: 32px;
        border: 1px solid red;
        margin: 0;
        padding: 0;
    }
`

const Message = styled.li`
    margin-right: ${({me}) => me ? '0' : 'auto'};
    margin-left: ${({me}) => me ? 'auto' : '0'};
    background: ${({me}) => me ? 'white' : '#f5f5f5'};
    color: ${({me}) => me ? '#242424' : '#242424'};
    text-align: ${({me}) => me ? 'right' : 'left'};
    max-width: 75%;
    padding: .66rem 1rem;
    display: inline-block;
    /* border: 1px solid #dbdbdb; */
    & + & {
        margin-top: 1rem;
    }
`

const Messages = styled.ul`
    box-sizing: border-box;
    padding: 2rem 1rem;
    margin: 0;
    list-style: none;
    background: rgba(255, 255, 255, 0.2);

    & > li {
        border-radius: 8px;
    }
`


const SendButton = styled.button`
    transition: all 0.33s;
    background: white;
    & > svg  path {
        fill: ${({active}) => active ? 'dodgerblue' : '#242424'};
    }
`

const InputGroup = styled.form`

    display: grid;
    grid-template-columns: 75% 25%;
    border-top: 1px solid #dbdbdb;
    padding-bottom: 4px;
    ${SendButton},
    input {
        cursor: none;
        outline: none;
        border: none;
        display: block;
        padding: 0;
        height: 56px;
        border-radius: 0px;
        box-sizing: border-box;
        font-family: 'IBM Plex Sans';
        letter-spacing: 0.5px;
        font-size: 1rem;
    }

    input {
        padding: 0px 1rem;
    }
    ${SendButton} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`




const Client = () => {

    const [server, setServer] = useState();
    const [clients, setClients] = useState([]);
    const [userID, setUserID] = useState('');
    const [message, setMessage] = useState('');

    const [data, setData] = useState([]);

    const send2server = (payload) => {
        server.send(JSON.stringify(payload));
    }

    const test = () => {
        send2server({
            type: 'STATE_CHANGE',
            content: {
                userId: userID,
                message: message
            }
        });
        setMessage('');
    }

    // Set up WebSocket connection
    useEffect(()=>{
        const ws = new WebSocket('ws://localhost:9898');
        ws.onopen = () => {
            console.log('WebSocket client has connected.');
            setServer(ws);
        };
        ws.onmessage = (e) => {
            const rx = JSON.parse(e.data);
            switch (rx.type) {
                case 'PLAYER_UPDATE':
                    setClients(rx.content);
                    setUserID(rx.userID);
                    break;
                case 'STATE_CHANGE':
                    setData(oldData => [...oldData, rx.content]);
                    break;
            }
        }
    }, [])

    return (
        <Wrapper>
            <Users>
                {clients.map(c => <li></li>)}
            </Users>
            <Messages>
                {data.map(msg => {
                    return <Message me={msg.userId == userID}>{msg.userId} says {msg.message}</Message>
                })}
            </Messages>
            <InputGroup onSubmit={(e)=>{  e.preventDefault(); if (message.length) test()}}>
                <input onChange={(e) => setMessage(e.target.value)} value={message} placeholder="Type your message... " type="text"/>
                <SendButton onClick={() => {if (message.length) test()}} active={message.length}>
                    <Icon></Icon>
                </SendButton>
            </InputGroup>
        </Wrapper>
    );
}

export default Client