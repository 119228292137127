import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import styled from 'styled-components';
import List from './List';


const TrayWrapper = styled.div.attrs( ({deltaY, anchor}) => ({
    style: {
      transform: `translate3d(0, ${anchor}px, 0) translate3d(0, ${deltaY}px, 0)`,
      transition: deltaY === 0 ? 'transform 0.33s' : 'none',
      overflow: true ? 'hidden' : 'scroll'
    }
  }))`
    width: 100%;
    height: calc(100% - 0px);
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    /* border-top: 1px solid #e2e4e6; */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    will-change: transform;
    z-index: 3;

    &::after {
        content: '';
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6rem;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .66) 50%, rgba(255, 255, 255, 1) 100%);
        pointer-events: none;
    }
  `
  
  const TrayHandle = styled.div`
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.66s;
    border-bottom: ${props => props.navState ? 'none' : '1px solid #e2e4e6'};
    /* box-shadow: ${props => props.navState ? 'none' : ' 0px 0px 4px 0px rgba(0, 0, 0, 0.1)'}; */
    &::after {
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        background: url(./images/menu-8.svg);
        background-size: cover;
        opacity: 0.33;
        transform:  ${props => props.navState ? 'none' : 'scaleY(-1)'};
    }

  `

  const TrayScroller = styled.div.attrs( ({opac}) => ({
    style: {
      opacity: opac
    }
  }))`
      transition: opacity 0.15s;
      height: calc(100% - 32px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 1rem 1rem 6rem;
      box-sizing: border-box;
  `

const Scroller = memo(({ScrollerRef, opacity, closeHandler}) => {
    return (
        <TrayScroller ref={ScrollerRef} opac={opacity}>
            <List clickHandler={closeHandler}/>
        </TrayScroller>
    )
}, (prevProps, nextProps) => { return (prevProps.ScrollerRef === nextProps.ScrollerRef && prevProps.opacity === nextProps.opacity && nextProps.closeHandler === prevProps.closeHandler) })

const Tray = ({navHandler, navState}) => {

    const [deltaY, changeDelta] = useState(0);
    const [anchor, changeAnchor] = useState(0);

    const [opacity, changeOpac] = useState(0);
    
    const rAF = useRef();

    const traySize = useRef();
    const yDirection = useRef();
    const yLast = useRef();
    const yStart = useRef();
    
    const TrayRef = useRef();
    const HandleRef = useRef();
    const ScrollerRef = useRef();

    useEffect(()=>{
        traySize.current = TrayRef.current.getBoundingClientRect().height - 32 - 65;
        changeAnchor(traySize.current);
    }, [])

    const startHandler = (e) => {
        yStart.current = e.changedTouches[0].clientY;
        yLast.current = e.changedTouches[0].clientY;
    }

    const moveHandler = (e) => {
        yDirection.current = e.changedTouches[0].clientY - yLast.current > 0 ? 'down' : 'up';
        const opac = (1 - (TrayRef.current.getBoundingClientRect().top - 0) / TrayRef.current.getBoundingClientRect().height) / 2;
        if ((ScrollerRef.current.scrollTop === 0) || (e.changedTouches[0].target === HandleRef.current)) {
            const delta = e.changedTouches[0].clientY - yStart.current;
            if (TrayRef.current.getBoundingClientRect().bottom + delta + anchor >= window.innerHeight) {
                rAF.current = requestAnimationFrame(() => {
                    move(delta, opac);
                })
            }
        } else {
            yStart.current = e.changedTouches[0].clientY;
        }
        yLast.current = e.changedTouches[0].clientY;

        // const opac = (1 - (TrayRef.current.getBoundingClientRect().top - 0) / TrayRef.current.getBoundingClientRect().height) / 3;
        // if (yDirection.current == 'down') {
        //     if (ScrollerRef.current.scrollTop != 0 && e.changedTouches[0].target != HandleRef.current) {
        //         yStart.current = e.changedTouches[0].clientY;
        //     } else {
        //         const delta = e.changedTouches[0].clientY - yStart.current;
        //         rAF.current = requestAnimationFrame(() => {
        //             move(delta, opac);
        //         })
        //     }
        // } else {
        //     const delta = e.changedTouches[0].clientY - yStart.current;
        //     if (TrayRef.current.getBoundingClientRect().bottom + delta + anchor > window.innerHeight) {
        //         rAF.current = requestAnimationFrame(() => {
        //             move(delta, opac);
        //         })
        //     }
        // }

    }

    const move = (delta, opac) => {
        changeDelta(Math.floor(delta));
        changeOpac(opac);
    }
    
    const closeTray = useCallback(() => {
        changeAnchor(traySize.current);
        navHandler(true);
        changeOpac(0);
    }, []);


    const endHandler = (e) => {

        if (yDirection.current === 'down' && TrayRef.current.getBoundingClientRect().top > window.innerHeight/8) {
            closeTray();
        } else if (yDirection.current === 'up') {
            changeAnchor(0);
            navHandler(false);
            changeOpac(1);
        } else {
            changeOpac(1);
        }
        yStart.current = 0;
        cancelAnimationFrame(rAF.current);
        changeDelta(0);
    }
    
    return (
      <TrayWrapper ref={TrayRef} anchor={anchor} deltaY={deltaY} onTouchStart={startHandler} onTouchEnd={endHandler} onTouchCancel={endHandler} onTouchMove={moveHandler}>
        <TrayHandle navState={navState} ref={HandleRef}></TrayHandle>
        <Scroller ScrollerRef={ScrollerRef} opacity={opacity} closeHandler={closeTray}/>
      </TrayWrapper>
    )
  }

export default Tray;