import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#FFFFFF" strokeLinecap="square" transform="translate(1 1)">
          <path d="M0.5 0.5L6.5 6 0.5 11.5"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
