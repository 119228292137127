import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.main`
    padding: 2rem;
    text-align: center;
`

const FourOhFour = () => (
    <Wrapper>
        <h1>404 Page Not Found</h1>
        <p>The page you're looking for cannot be found.</p>
    </Wrapper>
)

export default FourOhFour;