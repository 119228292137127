import React from 'react';
import { useTrail, animated } from 'react-spring'
import styled from 'styled-components'

const Item = styled.div`
    position: relative;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-family: 'IBM Plex Sans Condensed';
    font-size: 4em;
    font-weight: 400;
    will-change: transform, opacity;
    overflow: hidden;
    & > div {
        display: flex;
        align-items: bottom;
        transform-origin: 100% 100%;
    }
`;
const AnimatedItem = animated(Item);

function Trail({ children }) {
  const items = React.Children.toArray(children)
  
  const opacityTrail = useTrail(items.length, {
    config: { mass: 5, tension: 1750, friction: 650 },
    delay: 666,
    opacity: 1,
    from: { opacity: 0 }
  });

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 1750, friction: 350 },
    delay: 666,
    x: 0,
    height: 80,
    from: { x: 20, height: 0 }
  });

  return (
    <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <AnimatedItem
            key={`FadeText-${index}`}
            className="trails-text"
            style={{ ...rest }}>
            <animated.div style={{ opacity: opacityTrail[index].opacity, transform: x.interpolate((x) => `translate3d(0,${x}px,0) rotateZ(-${x/5}deg)`) }}>{items[index]}</animated.div>
          </AnimatedItem>
        ))}
    </div>
  )
}

export default Trail;