import React, { useState } from 'react';

const locales = [
    {id: 'en', title: 'English', isDefault: true}
    ,{id: 'fr', title: 'Français'}
    // ,{id: 'es', title: 'Spanish'}
    // ,{id: 'nb', title: 'Norwegian'}
]

const LocaleContext = React.createContext();

const LocaleProvider = ({ children }) => {

    const [locale, setLocale] = useState(JSON.parse(localStorage.getItem('locale')) || 'en');
    const [activeCursor, activateCursor] = useState(false);

    const changeLocale = (loc) => {
      localStorage.setItem('locale', JSON.stringify(loc));
      setLocale(loc);
    }

    return (
        <LocaleContext.Provider value={{locale, locales, changeLocale, activeCursor, activateCursor}}>
            {children}
        </LocaleContext.Provider>
    )

}

export { LocaleProvider, LocaleContext };