import React, { useState, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'lodash';
import useSanity from './hooks/useSanity';



function Shrink() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        x="0"
        y="0"
        viewBox="0 0 16 16"
      >
        <g fill="#444">
          <path
            d="M15.707 1.707A1 1 0 1014.293.293l-2.5 2.5L9 0v7h7l-2.793-2.793 2.5-2.5z"
            data-color="color-2"
          ></path>
          <path d="M2.793 11.793l-2.5 2.5a1 1 0 101.414 1.414l2.5-2.5L7 16V9H0l2.793 2.793z"></path>
        </g>
      </svg>
    );
}
function Expand() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        x="0"
        y="0"
        viewBox="0 0 16 16"
      >
        <g fill="#444">
          <path
            d="M7.4 10L6 8.6 3.3 11.3 0 8 0 16 8 16 4.7 12.7z"
            data-color="color-2"
          ></path>
          <path d="M11.3 3.3L8.6 6 10 7.4 12.7 4.7 16 8 16 0 8 0z"></path>
        </g>
      </svg>
    );
}

const StoriesWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: #121212;

    & > div:nth-child(1) {
        display: flex;
        z-index: 1;
        position: absolute;
        width: 100%;
        top: 0px;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(100,100,100,0.33) 0%, rgba(50,50,50,0) 100%);
        /* grid-template-columns: repeat(auto, 1fr); */
        /* height: 40px; */
    }
    & > div:nth-child(2) {
        position: relative;
        height: 100%;
        width: 100%;
    }
    /* background: white; */
    & > div:nth-child(2) > div {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 65px);
        height: 100%;
        width: 100%;
        & > img {
            object-fit: ${({fullscreen}) => {return fullscreen ? 'cover' : 'contain'}};
            height: 100%;
            width: 100%;
            object-position: 50% 50%;
            transition: all 1s;
        }
    }
`
const shrink = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`
const StoryTimer = styled.div`
    /* position: absolute; */
    /* top: 0; */
    width: calc(100% - 10px);
    margin: 15px 5px 35px 5px;
    &:first-of-type {
        width: calc(100% - 15px);
        margin: 15px 5px 35px 10px;
    }
    &:last-of-type {
        width: calc(100% - 15px);
        margin: 15px 10px 35px 5px;
    }
    height: 3px;
    /* border: 10px solid blue; */
    background: rgba(255, 255, 255, 0.33);
    box-sizing: border-box;
    /* opacity:  ${({shown}) => {return shown ? '1' : '0'}}; */
    border-radius: 10px;
    overflow: hidden;
    &::before {
        animation: ${({shown}) => {return shown ? shrink : 'none'}} 5s linear;
        animation-fill-mode: forwards;
        content: '';
        display: block;
        height: 100%;
        width: 0%;
        background: white;
    }
`

const Story = styled.div`
    /* opacity: ${({shown}) => {return shown ? '0' : '1';}}; */
    
    /* transition: all 3s; */
    /* transition: ${({shown}) => {return shown ? 'none' : 'all'}} 1s; */
    opacity:  ${({shown}) => {return shown ? '1' : '0'}};
    /* transform:  ${({shown}) => {return shown ? 'translate(0%, 0%)' : 'translate(-100%, 0%)'}}; */
    transition: all 1s;
    & > div {
        background: white;
        color: black;
        position: absolute;
        bottom: 25px;
        right: 25px;
        padding: 20px 20px;
        z-index: 2;
        cursor: pointer;
        border-radius: 50px;
        & > svg {
            height: 18px;
            width: 18px;
            display: block;
        }
    }
`

const Stories = () => {
    
    const query = `
        *[_type == "album" && _id == "f3f93a63-3dd5-4100-9c71-f42465b9ef66"][0] {
            _id,
            content[] {
                "image": image.asset->url
            }
        }
    `
    const [result, loading] = useSanity(query);
    const [story, setStory] = useState(0);
    const [fullscreen, setFullscreen] = useState(true);
    
    console.log(result)

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //           setStory(1);
    //     }, 1000);
    //     return () => clearTimeout(timer);
    // }, []);

      return (
        !loading ? <StoriesWrapper fullscreen={fullscreen}>
            <div>
                {result.content.map((s,i) => {
                    return (
                        <StoryTimer onAnimationEnd={()=>{setStory((oldStory) => {return (oldStory+1)%result.content.length })}} shown={story == i}/>
                    )
                })}

            </div>
            <div>
                {result.content.map((s,i) => {
                    return (
                        <Story shown={story == i}>
                            <img onClick={()=>{setStory((oldStory) => {return (oldStory+1)%result.content.length })}} src={s.image}/>
                            <div onClick={()=>{setFullscreen(!fullscreen)}}>
                                {fullscreen ? <Shrink/> : <Expand/> }
                            </div>
                        </Story>
                    )
                })}
            </div>
        </StoriesWrapper> : <div>Loading</div>
    )
}

export default Stories;