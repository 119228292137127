import React, { useState, useEffect, useCallback } from 'react';

const MenuStateContext = React.createContext();

const MenuStateProvider = ({ children }) => {

    const [menuVisible, makeMenuVisible] = useState(false);

    return (
        <MenuStateContext.Provider value={{menuVisible, makeMenuVisible}}>
            {children}
        </MenuStateContext.Provider>
    )
}

export { MenuStateProvider, MenuStateContext };

