import React, { useState } from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import Link from './Link'

const Wrapper = styled.section`
    ${({orientation}) => orientation === 'horizontal' && `
        @media only screen and (min-width: 768px) {
            grid-template-columns: repeat(3, minmax(0, 420px));
        }
    `}
    display: grid;
    justify-content: center;
    margin: 3rem auto;
    & > div {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-gap: 3rem;
        padding: 4rem 2rem;
        ${({orientation}) => orientation === 'horizontal' ? `
            grid-template-rows: auto;
            grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
            @media only screen and (min-width: 768px) {
                grid-template-rows: auto 1fr;
                grid-template-columns: minmax(0, auto);
            }
            & + div {
                border-left: 0.5px solid #e5e5e5;
            }
        ` : `
            max-width: 768px;
            grid-template-columns: minmax(0, auto);
            grid-template-rows: minmax(0, auto) minmax(0, auto);
            grin-gap: 2rem;
            @media only screen and (min-width: 420px) {
                grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
                grin-gap: 2vw;
            }

        `}

        ${({orientation}) => orientation === 'horizontal' && `
            & > div:nth-child(1) {
                order: 1;
                @media only screen and (min-width: 768px) {
                    order: 0;
                }
            }
        `}

        & > div:nth-child(2) {
            & > div > span {
                opacity: 0.33;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 0.66rem;
                display: block;
                ${({orientation}) => orientation === 'horizontal' ? `
                    margin-bottom: .66rem;
                ` : `
                    margin-bottom: .33rem;
                `}
                
            }
            display: flex;
            flex-direction: column;
            ${({orientation}) => orientation === 'horizontal' ? `
                justify-content: space-between;
                text-align: left;
                align-items: left;
                @media only screen and (min-width: 768px) {
                    align-items: center;
                    text-align: center;
                }

            ` : `
                justify-content: center;
                align-items: flex-start;
                text-align: left;
            `}
            height: 100%;
            box-sizing: border-box;
            & > a {
                font-size: 14px;
                font-weight: 700;
                margin-top: 2rem;
                color: #0f886f;
                color: #242424;
            }
        }
    }
`

const Photo = styled.div`
    ${({orientation}) => orientation === 'horizontal' ? `
        width: 33%;
        padding-bottom: 33%;
        margin-bottom: 2rem;
    ` : `
        width: 100%;
        padding-bottom: 75%;
        order: 2;
    `}
    position: relative;
    border-radius: 5px;
    background: ${({src}) => `url(${src})`};
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 1;
    perspective: 1000;
    transform-style: preserve-3d;
    &:after{
        content: '';
        position: absolute;
        background: ${({src}) => `url(${src})`};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        bottom: 0px;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: translate3d(-50%, 5%, -100px);
        filter: blur(10px);
        border-radius: 10px;
    }
`

const ContentGroupWidget = ({orientation, groups}) => {
    return <Wrapper orientation={orientation}>
        {groups.map((cg) => {
            return (
                <div>
                    <Photo orientation={orientation} src={cg.image}/>
                    <div>
                        <div>
                            <span>{cg.tag}</span>
                            <BlockContent blocks={cg.content}></BlockContent>
                        </div>
                        {cg.action.label && <Link to={cg.action.destination}>{cg.action.label} ›</Link>}
                    </div>
                </div>
            )
        })}
    </Wrapper>
}

export default ContentGroupWidget;