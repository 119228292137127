import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSpring, useTrail, useTransition, animated, interpolate } from 'react-spring'


const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    background: white;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
`

const AnimatedItem = animated(Item);

function Trail({ children }) {
    
    const items = React.Children.toArray(children)
    
    const opacityTrail = useTrail(items.length, {
      config: { mass: 5, tension: 1750, friction: 650 },
      delay: 1200,
      from: { opacity: 0 }
    });
  
    const trail = useTrail(items.length, {
      config: { mass: 5, tension: 1750, friction: 350 },
      delay: 1200,
      x: 0,
      opacity: 1,
      from: { x: 20, opacity: 0 }
    });
  
    return (
      <Wrapper>
          {trail.map(({ x, ...rest }, index) => (
            <AnimatedItem key={`HomepageTile-${index}`} style={{
                //  transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
                 ...rest
                }}>
              {items[index]}
            </AnimatedItem>
          ))}
      </Wrapper>
    )
  }

  const Wrapper = styled.div`
    padding: 0rem 2rem 4rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
`


const HomepageTiles = () => {

    return (
        <Trail>
            <div>
                <h2>Front End Development</h2>
            </div>
            <div>
                <h2>Mobile App Design</h2>
            </div>
            <div>
                <h2>Prototyping</h2>
            </div>
            <div>
                <h2>Strategy</h2>
            </div>
            <div>
                <h2>CRM</h2>
            </div>
            <div>
                <h2>About</h2>
            </div>
            <div>
                <h2>Selected Work</h2>
            </div>
            <div>
                <h2>Contact</h2>
            </div>
        </Trail>
    )
}


export default HomepageTiles;