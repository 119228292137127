import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0turn);
    }
    100% {
        transform: rotate(1turn);
    }
`

function Icon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        x="0"
        y="0"
        viewBox="0 0 48 48"
      >
        <g fill="#444">
          <path d="M24 1a1 1 0 00-1 1v8a1 1 0 002 0V2a1 1 0 00-1-1z"></path>
          <path
            d="M33.899 15.101a.997.997 0 00.707-.293l5.657-5.657a.999.999 0 10-1.414-1.414l-5.657 5.657a.999.999 0 00.707 1.707z"
            opacity="0.9"
          ></path>
          <path d="M46 23h-8a1 1 0 000 2h8a1 1 0 000-2z" opacity="0.8"></path>
          <path
            d="M34.606 33.192a.999.999 0 10-1.414 1.414l5.657 5.657a.997.997 0 001.414 0 .999.999 0 000-1.414l-5.657-5.657z"
            opacity="0.7"
          ></path>
          <path
            d="M24 37a1 1 0 00-1 1v8a1 1 0 002 0v-8a1 1 0 00-1-1z"
            opacity="0.6"
          ></path>
          <path
            d="M13.394 33.192l-5.657 5.657a.999.999 0 101.414 1.414l5.657-5.657a.999.999 0 10-1.414-1.414z"
            opacity="0.5"
          ></path>
          <path
            d="M11 24a1 1 0 00-1-1H2a1 1 0 000 2h8a1 1 0 001-1z"
            opacity="0.4"
          ></path>
          <path
            d="M9.151 7.737a.999.999 0 10-1.414 1.414l5.657 5.657a.997.997 0 001.414 0 .999.999 0 000-1.414L9.151 7.737z"
            opacity="0.3"
          ></path>
        </g>
      </svg>
    );
  }

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-basis: auto;
    align-items: center;
    color: black;
    justify-content: space-around;
    svg {
        opacity: 0.66;
        display: block;
        height: 50px;
        width: 50px;
        animation: ${spin} 1.5s linear infinite;
        transform-origin: middle middle;
        circle {
            stroke: red;
            fill: red;
        }
    }
`

const Loader = () => {
    return (
        <Wrapper>
            <Icon></Icon>
        </Wrapper>
    )
}

export default Loader;