// Libraries
import React from 'react'
import styled from 'styled-components'
import ProjectCard from './project-card'

import { useTrail, animated } from 'react-spring'

const Item = styled.div`
    position: relative;
    will-change: transform, opacity;
    /* overflow: hidden; */
    /* & > div {
        display: flex;
        align-items: bottom;
        transform-origin: 100% 100%;
    } */
`;
const AnimatedItem = animated(Item);

function Trail({ children }) {
  const items = React.Children.toArray(children)
  
  const opacityTrail = useTrail(items.length, {
    config: { mass: 5, tension: 1750, friction: 650 },
    delay: 333,
    opacity: 1,
    from: { opacity: 0 }
  });

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 1750, friction: 350 },
    delay: 333,
    x: 0,
    from: { x: 20 }
  });

  return (
    <>
        {trail.map(({ x, height, ...rest }, index) => (
          <AnimatedItem
            key={`FadeText-${index}`}
            style={{ ...rest }}>
            <animated.div style={{ opacity: opacityTrail[index].opacity, transform: x.interpolate((x) => `translateY(${x}px)`) }}>{items[index]}</animated.div>
          </AnimatedItem>
        ))}
    </>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  /* padding: 0rem 0rem; */
  width: 90%;
  & > div {
    margin-top: 10vh;
  }
  & > div + div {
    margin-top: 5vw;
  }
  & > div:last-of-type {
    margin-bottom: 5vw;
  }
  @media only screen and (min-width: 768px) {
    width: 85%;
    & > div {
      margin-top: 15vh;
    }
  }
  @media only screen and (min-width: 1024px) {
    /* padding: 0rem; */
  }
`
const ProjectStream = ({ projects }) => {
  return (
    <Wrapper>
      {/* <Trail> */}
        {projects?.map((card, key) => {
          return <ProjectCard key={`pcard${key}`} card={card} />
        }) }
      {/* </Trail> */}
    </Wrapper>
  )
}

export default ProjectStream
